.drawerFormAddCuotes{
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
    width: 100%;
    overflow: auto;
    /* margin-top: 400px; */
    /* margin-top: 100px; */
    /* height: 711px; */
    /* border-radius: 10px; */
    /* align-items: center; */

}

.drawerFormAddCuotes2{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    /* margin-top: 100px; */
    /* height: 711px; */
    /* border-radius: 10px; */
    /* align-items: center; */
}

.infoTwoAddProduct{
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-right: 10px;
    padding-left: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
}

.inputProductContainer {
    position: relative;
    margin-bottom: 20px;
  }
  
  .inputProductContainer .inputProducts {
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 10px;
    border: none;
    /* border-bottom: 1px solid #ccc; */
    height: 38px;
    /* color: white; */
    width: 240px;
    /* padding: 10px; */
    padding-left: 10px;
    padding-right: 10px;
    font-size: 16px;
    transition: border-bottom-color 0.3s ease;
    background-color: transparent; /* Agregamos esta línea */
  }
  
  .inputProductContainer .inputProducts:focus {
    outline: none;
    border-bottom-color: #007bff;
    background-color: #8bbcf025;
  }
  
  .inputProductContainer label {
    position: absolute;
    bottom: 20px;
    left: 10px;
    font-size: 16px;
    /* color: #aaa; */
    transition: all 0.3s ease;
    pointer-events: none;
  }
  
  .inputProductContainer .inputProducts:focus + label,
  .inputProductContainer .inputProducts:not(:placeholder-shown) + label {
    bottom: 42px;
    left: 5px;
    font-size: 12px;
    color: #007bff;
  }

  .inputProductContainer .inputSelectIva {
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 10px;
    border: none;
    border-bottom: 1px solid #ccc;
    height: 38px;
    width: 240px;
    padding: 10px;
    color: white;
    font-size: 16px;
    transition: border-bottom-color 0.3s ease;
    color: white;
    background-color: transparent;
  }
  
  .inputProductContainer .inputSelectIva:focus {
    outline: none;
    border-bottom-color: #007bff;
  }
  
  .inputProductContainer .inputSelectIva + label,
  .inputProductContainer .inputSelectIva:not(:placeholder-shown) + label {
    top: -20px;
    left: 5px;
    font-size: 12px;
    color: #007bff;
  }

.ContainerProduct{
    display: flex;
    align-items: center;
    justify-content: center;
}

.containerInfoProduct{
    display: flex;
    flex-direction: row;
    width: 650px;
    background-color: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(20px);
    border-radius: 10px;
    margin-top: 30px;
    margin-bottom: 110px;
    box-shadow: 0 0 10px 0 #00000068 inset, 0 0 10px 4px #00000068;
    position: relative;
    align-items: center;
    justify-content: center;
}

.containeProductAdd{
    display: flex;
    flex-wrap: wrap;
    /* height: 120px; */
    align-items: center;
    justify-content: space-around;
    box-sizing: border-box;
    /* align-items: center;
    justify-content: center;
    margin-top: 100px; */
}

.buttonContainerProduct{
    display: flex;
    flex-direction: row;
    /* margin-left: 450px; */
}

@media screen and (max-width: 800px){
    .containerInfoProduct{
        width:100vw;
    }

    .divProduct{
        margin-top: 0px;
        margin-left: 0px;
    }
    
    .divMaterial{
        margin-top: 0px;
        margin-left: 0px;
    }
    .buttonContainerProduct{
        display: flex;
        flex-direction: row;
        /* margin-top: 200px; */
        /* margin-left: 350px; */
    }
}

