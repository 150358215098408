.drawerFormProducts {
    /* margin-bottom:50px; */
    display: flex;
    flex-direction: column;
    /* align-items: center;
    justify-content: center; */
    width: 100%;
    /* height: 711px; */
    /* border-radius: 10px; */
}

.containeQuotestAdd {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 0 5%;
    margin-bottom: 10px;
    /* height: 100px; */
    /* align-items: center;
    justify-content: center;
    margin-top: 100px;*/
}

.moreHeigth{
    height: 150px !important;
}
.observationHeigth{
    top: 0px !important;
}

.containerButtonAddlist {
    display: flex;
    flex-direction: row;
    padding-top: 30px;
}

.containeQuotestAdd2 {
    display: flex;
    flex-wrap: wrap;
    /* height: 100px; */
}

.containerQuotes {
    display: flex;
    flex-direction: row;
    width: 75%;
    /* background-color: rgba(0, 0, 0, 0.3); */
    backdrop-filter: blur(20px);
    border-radius: 10px;
    margin-top: 40px;
    box-shadow: 0 0 10px 0 #00000068 inset, 0 0 10px 4px #00000068;
}

.containerQuote3 {
    display: flex;
    flex-direction: row;
    width: 75%;
    background-color: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(20px);
    border-radius: 10px;
    margin-top: 40px;
    box-shadow: 0 0 10px 0 #00000068 inset, 0 0 10px 4px #00000068;
}

.containerQuotes2 {
    display: flex;
    flex-direction: row;
    width: 75%;
    margin-bottom: 90px;
    /* background-color: rgba(0, 0, 0, 0.3); */
    backdrop-filter: blur(20px);
    border-radius: 10px;
    margin-top: 40px;
    box-shadow: 0 0 10px 0 #00000068 inset, 0 0 10px 4px #00000068;
}

.infoOneQuotes {
    margin-top: 30px;
    margin-bottom: 30px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.infoTwoQuotes {
    width: 55%;
    margin-top: 30px;
    margin-bottom: 70px;
    margin-right: 10px;
    padding-left: 100px;
}

.ContainerAddCuotes {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.inputQuotesContainer {
    position: relative;
    margin-bottom: 40px;
}

.inputQuotesUnit{
    
}

.inputQuotesContainer .inputquotes {
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 28px;
    border: none;
    border-bottom: 1px solid #ccc;
    color: white;
    height: 38px;
    width: 250px;
    /* padding: 10px; */
    padding-left: 10px;
    padding-right: 10px;
    font-size: 16px;
    transition: border-bottom-color 0.3s ease;
    background-color: transparent;
    /* Agregamos esta línea */
}

.inputQuotesContainer .inputquotes:focus {
    outline: none;
    border-bottom-color: #007bff;
    background-color: #8bbcf025;
}

.containerFormulary{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.inputQuotesContainer .inputTimerQuotes {
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 10px;
    border: none;
    border-bottom: 1px solid #ccc;
    height: 38px;
    color: #ffffff;
    width: 250px;
    padding: 10px;
    font-size: 16px;
    transition: border-bottom-color 0.3s ease;
    background-color: transparent;
    /* Agregamos esta línea */
}

.swal2-container{
    z-index: 9999 !important;
}

.inputQuotesContainer .inputTimerQuotes:focus {
    outline: none;
    border-bottom-color: #007bff;
}

.inputQuotesContainer .inputquotes:focus+label,
.inputQuotesContainer .inputquotes:not(:placeholder-shown)+label {
    bottom: 50px;
    left: 5px;
    font-size: 14px;
    color: #007bff;
}

.inputQuotesContainer label {
    position: absolute;
    bottom: 30px;
    left: 10px;
    font-size: 16px;
    color: #aaa;
    transition: all 0.3s ease;
    pointer-events: none;
}

.inputQuotesContainer .inputTimerQuotes:focus+label,
.inputQuotesContainer .inputTimerQuotes:not(:placeholder-shown)+label {
    bottom: 50px;
    left: 5px;
    font-size: 15px;
    color: #007bff;
}

.inputQuotesContainer .textareaQuotes {
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 33px;
    border: none;
    border-bottom: 1px solid #ccc;
    width: 250px;
    /* padding: 10px; */
    padding-left: 10px;
    padding-right: 10px;
    height: 30px;
    font-size: 16px;
    transition: border-bottom-color 0.3s ease;
    background-color: transparent;
    resize: none;
}

.inputQuotesContainer .textareaQuotes:focus {
    outline: none;
    border-bottom-color: #007bff;
    background-color: #8bbcf025;
}

.inputQuotesContainer .textareaQuotes+label {
    position: absolute;
    bottom: 30px;
    left: 10px;
    font-size: 16px;
    color: #aaa;
    transition: all 0.3s ease;
    pointer-events: none;
    top: 0px;
}

.inputQuotesContainer .textareaQuotes:focus+label,
.inputQuotesContainer .textareaQuotes:not(:placeholder-shown)+label {
    bottom: 60px;
    left: 5px;
    font-size: 15px;
    color: #007bff;
    top: 0px;
    
}

.textareaQuotesHeight{
    height: 90px !important;
    background-color: #e3e3e355 !important;
}

.inputQuotesContainer .inputSelectClientQuotes {
    margin-top: 28px;
    margin-left: 10px;
    margin-right: 10px;
    border: none;
    border-bottom: 1px solid #ccc;
    height: 38px;
    width: 240px;
    padding: 10px;
    font-size: 16px;
    transition: border-bottom-color 0.3s ease;
    color: white;
    background-color: transparent;
}

.inputQuotesContainer .inputSelectClientQuotes:focus {
    outline: none;
    border-bottom-color: #007bff;
}

.inputQuotesContainer .inputSelectClientQuotes+label,
.inputQuotesContainer .inputSelectClientQuotes:not(:placeholder-shown)+label {
    top: 50px;
    left: 5px;
    font-size: 12px;
    color: #007bff;
}

.file-upload {
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 10px;
    margin-bottom: 20px;
    position: relative;
    width: 150px;
    height: 40px;
    background-color: transparent;
    border: 2px dashed #ccc;
    border-radius: 5px;
    overflow: hidden;
}

.centerStyle{
    display: flex;
    justify-content: center;
    width: 100%;
}

.file-upload:hover {
    background-color: #ffffff56;
    border: 2px dashed #007bff;
}

.file-upload.has-image {
    border-color: #4caf50;
    /* Cambia el color del borde a verde */
}

.file-upload.has-image:hover {
    background-color: #ffffff56;
    border: 2px dashed #107813;
}

.upload-label {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    cursor: pointer;
}

.upload-label:hover {
    background-color: #ffffff23;
}

.upload-text {
    margin-left: 8px;
    font-size: 14px;
    color: #ffffff;
}

.file-input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
}

.progress-bar {
    height: 4px;
    background-color: #4caf50;
    width: 0;
    transition: width 0.3s ease;
}

.file-input:valid+.progress-bar {
    width: 100%;
}

.matText {
    color: rgb(255, 255, 255);
}

.texProducttitule {
    color: white;
    margin-left: 10px;
}

/* .matText:hover{
} */

.rightQuotes {
    margin-left: 10px;
    margin-right: 10px;
}

.leftQuotes {
    margin-left: 10px;
    margin-right: 10px;
}

.buttonContainerAddQuotes {
    display: flex;
    flex-direction: row;
    justify-content: end;
    margin-top: 20px;
}

.buttonContainerAddQuotes2 {
    display: flex;
    flex-direction: row;
    justify-content: end;
    margin-top: 20px;
}

.itemPricesContainer {
    display: flex;
    flex-direction: row;
    padding-left: 10%;
    margin-top: 30px;
    width: 100%;
}

.containerTable {
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    max-height: 200px;
    max-width: 90%;
    overflow: visible scroll;
    padding-top: 40px;
}

.tableProduct {
    width: 100%;
    /* max-height: 200px; */
    overflow: hidden;
}

.containerTable::-webkit-scrollbar {
    height: 7px;
    width: 0px;
}

.containerTable::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 20px;
    border: 2px solid rgba(0, 0, 0, 0.3);
    color: #fff;
    /* Cambiamos el color de la letra a blanco */
}

.containerTable::-webkit-scrollbar-track {
    backdrop-filter: blur(4px);
    background-color: rgba(0, 0, 0, 0.3);
}

.thProduct {
    background-color: rgba(57, 54, 106, 0.571);
}

.bottomProductList {
    text-align: center;
    margin-top: 25px;
    width: 100px;
    background-color: rgb(136, 4, 30);
    border-radius: 10%;
    cursor: pointer;
}

.bottomProductList:hover {
    background-color: rgba(155, 3, 33, 0.525);
    box-shadow: 0 0 10px 0 #4c0505 inset, 0 0 10px 4px #4c0505;
}

.deleteButtonListProduct {
    display: flex;
    color: red;
    cursor: pointer;
    padding-left: 45%;
}

.deleteButtonListProduct:hover {
    color: #4c0505;
}

.minimal-table {
    border-collapse: collapse;
    width: 100%;
    background-color: #9b9b9b;
}

.minimal-table th,
.minimal-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

.minimal-table th {
    background-color: #454545;
}

.minimal-table tr:nth-child(even) {
    background-color: #f9f9f9;
}

@media screen and (max-width: 1372px) {


    .containerQuotes {
        width: 85%;
    }

    .containerQuote3 {
        width: 85%;
    }

    /*     .infoOneQuotes{
        margin-top: 30px;
        margin-bottom:70px;
        margin-left: 0px;    
        width: 100%;
        padding-left: 50px;
    } */

    .infoTwoQuotes {
        width: 100%;
        margin-top: 30px;
        margin-bottom: 70px;
        margin-right: 0px;
        /* padding-left: 65px; */
        /* border-left: 1px solid rgb(255, 255, 255); */
    }

    .containeQuotestAdd {
        flex-wrap: inherit;
        flex-wrap: wrap;
    }

    .containeQuotestAdd::-webkit-scrollbar {
        height: 7px;
        width: 0px;
    }

    .containeQuotestAdd::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.3);
        border-radius: 20px;
        border: 2px solid rgba(0, 0, 0, 0.3);
    }

    .containeQuotestAdd::-webkit-scrollbar-track {
        backdrop-filter: blur(4px);
        background-color: rgba(0, 0, 0, 0.3);
    }

    .containerQuotes2 {
        flex-wrap: wrap;
        width: 85%;
    }

    .containerQuotes2::-webkit-scrollbar {
        height: 7px;
        width: 0px;
    }

    .containerQuotes2::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.3);
        border-radius: 20px;
        border: 2px solid rgba(0, 0, 0, 0.3);
    }

    .containerQuotes2::-webkit-scrollbar-track {
        backdrop-filter: blur(4px);
        background-color: rgba(0, 0, 0, 0.3);
    }

    .buttonContainerAddQuotes {
        display: flex;
        flex-direction: row;
        justify-content: end;
        margin-top: 0px;
    }

    .buttonQuotes {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 8px;
        width: 103px;
        box-sizing: border-box;
        appearance: none;
        background-color: transparent;
        border-radius: 20px;
        cursor: pointer;
        display: flex;
        align-self: center;
        /* font-size: 1rem; */
        font-weight: 400;
        line-height: 1;
        margin: 6px;
        padding: 1.2em 2.8em;
        text-decoration: none;
        text-align: center;
        text-transform: uppercase;
        font-family: 'Montserrat', sans-serif;
        font-weight: 700;
    }

}

.fullscreen-overlay {
    /* position: fixed; */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.196);
    z-index: 9999;
}

.optionsWindowEditor {
    position: fixed;
    display: flex;
    flex-direction: row;
    top: 0;
    right: 0;
    padding-top: 10px;
    padding-right: 10px;
    z-index: 999999;
}

.CgMaximizeInMinimize {
    color: #007bff;
    height: 25px;
    width: 25px;
}

.GrCloseInMinimize {
    color: #4c0505;
    padding-left: 10px;
    height: 25px;
    width: 25px;
}

@media screen and (max-width: 1296px) {

    /* .containeQuotestAdd{
        overflow: visible;
        overflow-y: scroll;
    } */

    .inputQuotesContainer .inputquotes:focus+label,
    .inputQuotesContainer .inputquotes:not(:placeholder-shown)+label {
        /* margin-bottom: 40px; */
        /* left: 5px; */
        /* font-size: 14px; */
        /* color: #007bff; */
    }

    .inputQuotesContainer .textareaQuotes:focus+label,
.inputQuotesContainer .textareaQuotes:not(:placeholder-shown)+label {
    bottom: 40px;
}

    .inputQuotesContainer label{
        margin-bottom: 10px;
    }

    .inputQuotesContainer .textareaQuotes+label{
        margin-bottom: 5px;
    }

    .containeQuotestAdd::-webkit-scrollbar {
        height: 7px;
        width: 0px;
    }

    .containeQuotestAdd::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.3);
        border-radius: 20px;
        border: 2px solid rgba(0, 0, 0, 0.3);
    }

    .containeQuotestAdd::-webkit-scrollbar-track {
        backdrop-filter: blur(4px);
        background-color: rgba(0, 0, 0, 0.3);
    }

    /* .buttonContainerAddQuotes {
        display: flex;
        justify-content: end;
        margin-top: 42px;
        padding-right: 10px;
        margin-left: -29px;
    } */

    /* .containerTable{
        max-width: 70%;
        justify-content: start;
    } */

    .containeQuotestAdd2::-webkit-scrollbar {
        height: 7px;
        width: 0px;
    }

    .containeQuotestAdd2::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.3);
        border-radius: 20px;
        border: 2px solid rgba(0, 0, 0, 0.3);
    }

    .containeQuotestAdd2::-webkit-scrollbar-track {
        backdrop-filter: blur(4px);
        background-color: rgba(0, 0, 0, 0.3);
    }

}

@media screen and (max-width: 860px) {

    .texProducttitule {
        margin-left: 10px;
    }

    .containerQuotes {
        width: 100vw;
    }

    .containerQuotes2 {
        width: 100vw;
    }

    .containerQuote3 {
        width: 100vw;
    }

    .containerQuotes::-webkit-scrollbar {
        height: 7px;
        width: 0px;
    }

    .containerQuotes::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.3);
        border-radius: 20px;
        border: 2px solid rgba(0, 0, 0, 0.3);
    }

    .containerQuotes::-webkit-scrollbar-track {
        backdrop-filter: blur(4px);
        background-color: rgba(0, 0, 0, 0.3);
    }

    .containeQuotestAdd {
        width: 100%;
        justify-content: center;
    }

    .containeQuotestAdd2 {
        width: 100%;
        justify-content: center;
    }

    /*     .infoOneQuotes{
        margin-top: 30px;
        margin-bottom:70px;
        margin-left: 0px; 
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        padding-left: 32px;
    } */

    .infoTwoQuotes {
        width: 100%;
        margin-top: -81px;
        margin-bottom: 70px;
        margin-right: -10px;
        padding-left: 20px;
        /* border-left: 1px solid rgb(255, 255, 255); */
    }

    .buttonContainerAddQuotes {
        display: flex;
        /* flex-wrap: wrap; */
        justify-content: center;
        margin-top: 25px;
        width: 100%;
    }

    /* .containerTable{
        max-width: 200px;
    }
    .containerTable{
        width: 100%;
        padding-top: 60px;
    } */


}