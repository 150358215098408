.infoOneAddBill{
    width: 100% !important;
    border: none !important;
}

.containerLongText{
    display: flex;
}

.columnNameTable{
    /* height: 300px; */
}

.inputBillContainer{
    display: flex;
    align-items: end;
}