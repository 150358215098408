/* .containerAdd{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-left: 18px;
    padding-right: 18px;
} */

.containerButtonMaker{
    flex-direction: row;
    width: 100%;
    display: flex;
    justify-content: start;
    align-items: center;
    padding-left: 50px;
}

.containerButtonAdd{
    flex-direction: row;
    width: 60%;
    display: flex;
    justify-content: start;
    align-items: center;
}

.navIconAddQuotes:hover{
    box-shadow: 0 0 10px 0 #db3434 inset, 0 0 10px 4px #db3434;;
}

.navIconAddproducts:hover{
    box-shadow: 0 0 10px 0 #db3434 inset, 0 0 10px 4px #db3434;;
}

.navIconAddClient:hover{
    box-shadow: 0 0 10px 0 #db3434 inset, 0 0 10px 4px #db3434;;
}

.containerIconAddProduct{
    margin-top: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 10px;
    padding-bottom: 10px;
}

.containerIconAddProduct:hover{
    box-shadow: 0 0 10px 0 #3498db inset, 0 0 10px 4px #3498db;;
}

.textMakerIcon{
    margin-top: 10px;
}

.iconAdd{
    width: 20px;
    height: 20px;
    color: rgb(255, 255, 255);
    cursor: pointer;
}

.navIconAddClient{
    width: 100px;
    height: 100px;
    color: white;
    text-decoration: none;
}

.navIconAddproducts{
    width: 70px;
    height: 71px;
    text-decoration: none;
}

.iconAddQuotes{
    width: 50px;
    height: 50px;
    color: rgb(255, 255, 255);
    cursor: pointer;
}

.navIconAddQuotes{
    width: 20px;
    height: 20px;
    /* margin-bottom: 270px; */
    color: white;
    text-decoration: none;
}

.containerIconAddCompany{
    margin-top: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 10px;
    padding-bottom: 10px;
}

.navIconAddCompany{
    width: 100px;
    height: 100px;
    color: white;
    text-decoration: none;
}

@media screen and (max-width: 638px) {
    .containerButtonMaker{
        /* margin-left: 9px; */
    }
}

@media screen and (max-width: 412px) {
    .containerButtonMaker{
        /* margin-left: 20px; */
    }
}

/* @media screen and (max-width: 570px){
    .textMakerIcon{
        display: none;
    }
} */