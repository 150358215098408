.containerConfigAdmin{
    width: 50%;
}

.containerInfoConfigAdmin{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 10px 0 #00000068 inset, 0 0 10px 4px #00000068;
    backdrop-filter: blur(20px);
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 10px;
}

.mainContainerCompany{
    width: 80%;
    height: 80%;
    display: flex;
    justify-content: center;
}


.drawerFormAddCuotesAdmin{
    height: 100vh;
    padding-top: 150px;
}

.ContainerCompanyAddFormAdmin{

}

/* @media screen and (max-heigth: 1000px){
    .drawerFormAddCuotesAdmin{
        margin-top: 200px;
    }
} */