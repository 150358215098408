.overallContainerAdd {
    /* background: rgba(46, 57, 158, 0.233);
    backdrop-filter: blur(20px); */
    width: 100%;
    height: calc(100vh - 110px);
    color: white;
    display: flex;
    margin-bottom: 80px;
    flex-direction: column;
}

.containerLogoAdd {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 110px;
    /* position: fixed!important; */
    z-index: 999;
    /* background-color: rgba(82, 81, 81, 0.691); */
    backdrop-filter: blur(5px);
    /* background: linear-gradient(to bottom, rgba(82, 81, 81, 0.691) 70%,  rgba(158, 46, 46, 0.126)); */
    /* position: fixed; */
}

.containerLogoAddFixed{
    position: fixed;
}

.logoIntContainer {
    overflow: hidden;
    width: 200px;
    display: flex;
    justify-content: center;
    /* margin-left: 10px; */
    padding-bottom: 20px;
    padding-top: 20px;
    padding-left: 20px;
    cursor: pointer;
    /* background-color: yellowgreen; */
    /* Agregar prefijo de flex para Safari */
    -webkit-display: -webkit-flex;
    -webkit-justify-content: center;

}

.overallContainerAddCompany{
    display: flex;
    /* justify-content: center; */
    /* align-items: center; */
    flex-direction: row;
    /* padding-top: 10px; */
    height: 100vh;
    /* padding-bottom: 100px; */
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    /* overflow: hidden;*/
}

.containerTypeUser {
    margin-top: 2px;
    background-color: #cfcfcf17;
    border: 1px solid #cfcfcf29;
    border-radius: 5px;
    padding-left: 5px;
    box-sizing: border-box;
    padding-right: 5px;
    color: white;
}

.containerNameUser {
    color: white;
    /* margin-top: 5px; */
    display: flex;
    justify-content: center;
}

.textNameUser {
    /* background-color: #e1e1e124; */
    padding: 10px;
    margin: 0px;
    border-radius: 5px;
    margin-bottom: 10px;

}

.closeIntContainer {
    width: 20%;
    display: flex;
    justify-content: flex-end; /* Cambia "end" por "flex-end" */
    align-items: center; /* Alinea verticalmente */
    padding-right: 20px; /* Añade un espacio a la derecha para evitar superposiciones */}

.tittleAddIntContainer {
    width: 60%;
    display: flex;
    justify-content: center;
}

.containerLogoLoginAdd {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 110px;
    position: fixed !important;
    z-index: 999;
    background-color: rgba(82, 81, 81, 0.691);
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    overflow: hidden;
}

.ContaineProductAdd {
    display: flex;
    flex-direction: row;
    height: 120px;

    /* align-items: center;
    justify-content: center;
    margin-top: 100px; */
}

.logoAddWidth {
    margin-top: 20px;
    height: 50%;
    border-radius: 10px;
    width: 100%;
}

.logoAddHeigth {
    /* margin-top: 20px; */
    height: 100%;
    border-radius: 10px;
    /* width: 50%; */
}

.logoAdd2 {
    /* width: 100%; */
    /* height: 100%; */
    max-width: 100%; /* Ajusta el ancho al 100% si supera los 548px */
    max-height: 100%; /* Ajusta la altura al 100% si supera los 240px */
    object-fit: contain; /* Mantiene el aspecto de la imagen */
    width: auto;
    height: auto;
}

.conainerLogoCompany {
    width: 100%;
    height: 100%;
}

.tittleAdd {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: x-large;
    color: #fff;
    width: 55%;
}

.titleWithoutCompany {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: x-large;
    color: #fff;
    width: 100%;
}

.drawerFormCLient {
    /* margin-bottom:50px; */
    display: flex;
    flex-direction: column;
    /* align-items: center;
    justify-content: center; */
    width: 100%;
    margin-bottom: 200px;
    /* height: 711px; */
    /* border-radius: 10px; */

}

.containerInfo {
    /* margin-bottom: 80px; */
    display: flex;
    flex-direction: row;
    /* height: 500px; */
    width: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(20px);
    border-radius: 10px;
    margin-top: 30px;
    padding-bottom: 30px;
    box-shadow: 0 0 10px 0 #00000068 inset, 0 0 10px 4px #00000068;
    position: relative;
}

.infoOne {
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 10px;
    width: 50%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    border-right: 1px solid rgb(255, 255, 255);
}

.infoTwo {
    width: 50%;
    margin-bottom: 20px;
    margin-top: 20px;
    margin-right: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    /* border-left: 1px solid rgb(255, 255, 255); */
}

.containerNameClient {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    box-sizing: border-box;
    /* align-items: center;
    justify-content: center;
    margin-top: 100px; */
}

.Name {
    margin-right: 10px;
    height: 104px;
    box-sizing: border-box;
}

.lastNameOne {
    height: 104px;
    margin-right: 10px;
    box-sizing: border-box;

}

.typeDocument {
    height: 104px;
    margin-right: 10px;
    box-sizing: border-box;


}

.numberDocument {
    height: 104px;
    margin-right: 10px;
    box-sizing: border-box;
}

.Direction {
    height: 104px;
    margin-right: 10px;
    box-sizing: border-box;

}

.ContainerDocumentClient {
    display: flex;
    flex-direction: row;
    height: 120px;
    /* align-items: center;
    justify-content: center;
    margin-top: 100px; */
}


.containerContactClient {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    /* height: 300px; */
    box-sizing: border-box;
}

.Phone {
    height: 104px;
    margin-right: 10px;
    box-sizing: border-box;
}

.cell {
    height: 104px;
    box-sizing: border-box;
    margin-right: 10px;
}

.Mail {
    height: 104px;
    box-sizing: border-box;
    margin-right: 10px;
}

/* .contactClientAdd{
    margin-left: 70px;
} */

.containerEmailClient {
    display: flex;
    flex-direction: row;
}

.inputClientContainer {
    position: relative;
    margin-bottom: 40px;
}

.inputClientContainer .inputCLient {
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 10px;
    border: none;
    border-bottom: 1px solid #ccc;
    color: #ffffff;
    height: 38px;
    width: 200px;
    /* padding: 10px; */
    font-size: 16px;
    padding-left: 10px;
    padding-right: 10px;
    transition: border-bottom-color 0.3s ease;
    background-color: transparent;
    /* Agregamos esta línea */
}

.inputClientContainer .inputCLient:focus {
    outline: none;
    border-bottom-color: #007bff;
    background-color: #8bbcf025;
}

.inputClientContainer label {
    position: absolute;
    bottom: 20px;
    left: 20px;
    font-size: 16px;
    color: #aaa;
    transition: all 0.3s ease;
    pointer-events: none;
    /* margin-left: 10px;
    margin-right: 10px;
    margin-top: 10px;
    color: #ffffff;
    position: absolute;
    bottom: 0;
    left: 10px;
    font-size: 16px;
    color: #aaa;
    transition: all 0.3s ease;
    pointer-events: none; */
}

.inputClientContainer .inputCLient:focus+label,
.inputClientContainer .inputCLient:not(:placeholder-shown)+label {
    bottom: 40px;
    left: 5px;
    font-size: 14px;
    color: #007bff;
}

.inputClientContainer .inputSelectNT {
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 25px;
    color: #ffffff;
    border: none;
    border-bottom: 1px solid #ccc;
    height: 38px;
    width: 200px;
    padding: 10px;
    font-size: 16px;
    transition: border-bottom-color 0.3s ease;
    color: white;
    background-color: transparent;
}

.inputClientContainer .inputSelectNT:focus {
    outline: none;
    border-bottom-color: #007bff;
}

.inputClientContainer .inputSelectNT+label,
.inputClientContainer .inputSelectNT:not(:placeholder-shown)+label {
    bottom: 50px;
    left: 5px;
    font-size: 12px;
    color: #007bff;
}

.inputClientContainer2 {
    position: relative;
    margin-bottom: 40px;
}

.inputClientContainer2 .inputCLient2 {
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 10px;
    border: none;
    color: #ffffff;
    border-bottom: 1px solid #ccc;
    height: 38px;
    width: 200px;
    /* padding: 10px; */
    padding-left: 10px;
    padding-right: 10px;
    font-size: 16px;
    transition: border-bottom-color 0.3s ease;
    background-color: transparent;
    /* Agregamos esta línea */
}

.inputClientContainer2 .inputCLient2:focus {
    outline: none;
    border-bottom-color: #007bff;
    background-color: #8bbcf025;
}

.inputClientContainer2 label {
    position: absolute;
    bottom: 20px;
    left: 10px;
    font-size: 16px;
    color: #aaa;
    transition: all 0.3s ease;
    pointer-events: none;
}

.inputClientContainer2 .inputCLient2:focus+label,
.inputClientContainer2 .inputCLient2:not(:placeholder-shown)+label {
    bottom: 40px;
    left: 5px;
    font-size: 14px;
    color: #007bff;
}

.buttonContainerAdd {
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 30px;
}

.visibleKey {
    display: flex;
    flex-direction: row;
}

.containerInputPassword {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.buttonContainerAddClient {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: center;
    /* margin-top: 100px; */
}

.ContainerClient {
    width: 80%;
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
}

@media screen and (max-width: 1120px) {

    .ContainerClient {
        width: 90%;
    }

    .containerNameClient {
        justify-content: center;
        flex-direction: column;
        height: auto;
    }

    .containerContactClient {
        justify-content: center;
        flex-direction: column;
        height: auto;
    }

}

@media screen and (max-width: 700px) {

    .infoOne {
        border-right: 0px solid rgb(255, 255, 255);
    }

    .containerInfo {
        flex-direction: column;
        align-items: center;
        width: 100vw;
    }

    .tittleAdd {
        display: none;
    }

    .ContainerClient {
        width: 80%;
    }


}

@media screen and (max-width: 412px) {
    .logoIntContainer {
        width: 150px;
    }

    .closeIntContainer {
        /* width: 20%; */
        padding-right: 10px;
    }

    .logoAddHeigth {
        /* width: 90%; */
    }
}


/* @media screen and (max-width: 412px) { */
/* .logoIntContainer{
        width: 43.33%;
    } */

/* .containerTypeUser{
        width: 100px;
    } */
/* } */