.containerGeneralRemission{
    width: 1439px;
    display: flex;
    flex-direction: column;
    background-color: white;
    color: black;
}

.containerLogoRemission{
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 240px;
    /* padding-left: 15px;
    padding-right: 15px; */
    margin-top: 35px;
    box-sizing: border-box;
}

.containerImageLogoRemission{
    width: 72%;
    display: flex;
    height: 100%;
    box-sizing: border-box;
    align-items: end;
    flex-direction: column;
}

.remissionLogo {
    width: 627px;
    height: 40%;
    display: flex;
    justify-content: start;
    margin-right: 23px;
}

.containerTextAddressRemission{
    width: 100%;
    margin-top: 89px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.textAddressRemission{
    text-align: center;
    width: 100%;
    font-size: 19px;
    padding-left: 210px;
    margin: 0;
}

.expedition{
    display: flex;
    flex-direction: column;
    border: 2px solid #000000;
    border-radius: 5px;
    height: 84px;
    width: 200px;
}

.expeditionDate{
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: stretch;
    height: 100%;
}

.expeditionTittle{
    width: 100%;
    border-bottom: 2px solid #000000;
    text-align: center;
    background-color: grey;
}

.expeditionDay{
    flex-grow: 1;
    border-right: 2px solid #000000;
}

.expeditionMonth{
    flex-grow: 1;
    border-right: 2px solid #000000;
}

.expeditionYear{
    flex-grow: 1;
}


.expeditionTextDay{
    padding-top: 10px;
    text-align: center;
    margin: 0;
}

.expeditionTextMonth{
    padding-top: 10px;
    text-align: center;
    margin: 0;
}

.expeditionTextYear{
    padding-top: 10px;
    text-align: center;
    margin: 0;
}

.expeditionNumberDay{
    text-align: center;
    margin: 0;
}

.expeditionNumberMonth{
    text-align: center;
    margin: 0;
}

.expeditionNumbertYear{
    text-align: center;
    margin: 0;
}

.tittleTextRemission{
    text-align: center;
    margin: 0px;
    font-size: 26px;
    padding-top: 7px;
}

.numberRemission{
    text-align: center;
    padding-left: 31px;
    margin: 0px;
    padding-top: 6px;
    font-size: 15px;

}

.containerTableRemission{
    display: flex;
    flex-direction: column;
    width: 40%;
    align-items: start;
    padding-top: 89px;
}

.containerRemission{
    padding-left: 49px;
}

.referralPersonalData{
    padding-top: 25px;
    padding-left: 276px;
}

.lineBottom {
    padding-left: 3px;
    display: block;
    border-bottom: 1px solid black;
    width: 65.6%;
}

.sirClient{
    display: flex;
    flex-direction: row;
    font-size: 18px;
}

.lineBottom2{
    /* padding-top: 10px; */
    padding-left: 3px;
    display: block;
    border-bottom: 1px solid black;
    width: 32%;
}

.lineBottom3{
    /* padding-top: 10px; */
    padding-left: 3px;
    display: block;
    border-bottom: 1px solid black;
    width: 31.69%;
}

.sirClient2{
    padding-top: 10px;
    display: flex;
    flex-direction: row;
    font-size: 18px;
}
.lineBottom4{
    /* padding-top: 10px; */
    padding-left: 3px;
    display: block;
    border-bottom: 1px solid black;
    width: 66%;
}

.sirClient3{
    padding-top: 10px;
    display: flex;
    flex-direction: row;
    font-size: 18px;
}

.referralProductData{
    display: block;
    border: 2px solid #000000;
    border-radius: 5px;
    width: 73%;
    display: flex;
    flex-direction: column;
}

.ContainerReferralProductData{
    padding-top: 35px;
    padding-left: 272px;
}

.grupTittleReferralProductData{
    display: flex;
    flex-direction: row;
}

.quantityRemisiion{
    display: block;
    border-right: 2px solid #000000;
    border-bottom: 2px solid #000000;
    width: 10%;
    padding: 5px;
    text-align: center;
    font-size: 20px;
}

.articleRemisiion{
    display: block;
    border-right: 2px solid #000000;
    border-bottom: 2px solid #000000;
    width: 40%;
    padding: 5px;
    text-align: center;
    font-size: 20px;
}

.unitValueRemisiion{
    display: block;
    border-right: 2px solid #000000;
    border-bottom: 2px solid #000000;
    width: 25%;    
    padding: 5px;
    text-align: center;
    font-size: 20px;
}

.fullValueRemisiion{
    display: block;
    border-bottom: 2px solid #000000;
    width: 25%;
    padding: 5px;
    text-align: center;
    font-size: 20px;
}

.grupReferralProductData{
    display: flex;
    flex-direction: row;
}

.grupSigning{
    display: flex;
    flex-direction: row;
    height: 100%;
}

.signingRemission{
    width: 50.6%;
    display: flex;
    flex-direction: row;
    border-right: 2px solid #000000;
    padding: 5px;
    padding-top: 41px;
    text-align: center;
}

.signingTop1{
    text-align: center;
    display: block;
    border-top: 1px solid black;
    width: 40%;
    margin-right: 10%;
    margin-left: 10px;
    font-size: 20px;
}

.signingTop2 {
    display: block;
    border-top: 1px solid black;
    text-align: center;
    width: 40%;
    font-size: 20px;
}

.fullRemission{
    display: block;
    width: 24.6%;
    border-right: 2px solid #000000;
    background-color: grey;
    padding: 5px;
    padding-top: 20px;
    text-align: center;
    font-size: 20px;
}

.emptyRemission{
    display: block;
    width: 24.6%;
    background-color: grey;
    padding: 5px;
    text-align: center;
}

.containerWatermark{
    position: absolute;
    /* border: 1px solid #000000; */
    width: 856px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 31px;
}

.watermark{ 
    /* width: 21%; */
    height: 100%;
}


@supports(object-fit: cover){
    .watermark{
        object-fit:cover;
        object-position: center center;
    }
}
/* 
.watermark {
    width: 200px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-98px, 256px);
    font-size: 48px;
  } */