.containerConfig {
    width: 100%;
    overflow: auto;
    margin: 0 auto;
    /* padding: 20px; */
    font-family: Arial, sans-serif;
}
  
.titleConfig {
    text-align: center;
    font-size: 2em;
    color: #fff;
}
  
.menuConfig {
    margin-bottom: 20px;
}
  
.ulConfig {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: space-around;
    border-bottom: 2px solid #ccc;
}
  
.liConfig {
    margin: 0;
}
  
.aConfig {
    text-decoration: none;
    color: #ffffff;
    font-weight: bold;
    padding: 10px;
}
  
.aConfig:hover {
    color: #fff;
    background-color: #000;
}
  
.contentConfig {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
  
.subTitleConfig {
    margin-bottom: 10px;
    color: #fff;
}
  
.textConfig {
    font-size: 1.2em;
    line-height: 1.5em;
    color: #fff;
}

.colorIcon{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.iconNavConfig{
    width: 50px;
    height: 50px;
    margin: 15px;
}

.iconNavConfig:hover{
    transform: scale(1.5) rotate(360deg);
    transition: transform 1.5s ease-in-out;
}

.neonOptions:hover{
    transform: scale(1.5);
}

.circleOptions:hover{
    transform: scale(1.5);
}

.mainOptions:hover{
    transform: scale(1.5);
}

.secundaryOptions:hover{
    transform: scale(1.5);
}

.fontOptions:hover{
    transform: scale(1.5);
}

.tittle:hover{
    transform: scale(1.5);
}

.selected {
    transform: scale(0.8);
}
.selected2 {
    transform: scale(0.8);
}
.selected3 {
    transform: scale(0.8);
}
.selected4 {
    transform: scale(0.8);
}
.selected5 {
    transform: scale(0.8);
}
.selected6 {
    transform: scale(0.8);
}
.selected7 {
    transform: scale(0.8);
}
.selected8 {
    transform: scale(0.8);
}

.iconBackgroundImage{
    display: flex;
    flex-wrap: wrap;
    width: 70px;
    height: 70px;
    background-repeat: no-repeat;
    background-position: center;
    display: inline-block;
    margin: 10px;
}

.iconBackgroundImage:hover {
    transform: scale(5) translateY(-50%);
}