.contenedorgeneral-Table {
  display: flex;
  justify-content: center;
  /* align-items: center; */
  /* background-color: yellow; */
  margin-top: 46px;
  height: max-content;
  width: 100%;
}


.overallContainerTable {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 110px);
  box-sizing: border-box;
  min-width: calc(100vw - 100px);
  overflow: hidden;
  align-items: center;
}

.container-Table {
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
}

.containerFilter {
  display: flex;
  justify-content: center;
  color: #ffffff;
  width: 100%;
  padding-top: 10px;
}

.cardContainer {
  display: flex;
  /* max-height: 505px; */
  /* width: 95%; */
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
  padding-left: 20px;
  padding-right: 40px;
  /* overflow-y: scroll; */
}


.center {
  justify-content: center;
}

.card {
  /* background-color: #4b4b4ba7; */
  -webkit-backdrop-filter: blur(14px);
  backdrop-filter: blur(14px);
  background-size: cover;
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 2.5px;
  margin-bottom: 2.5px;

  border-radius: 10px;
  /* padding: 10px; 
  */
  /* padding-left: 20px; */
  transition: transform 0.3s ease;
  /* overflow: auto; */
}

.cardHeaderRow {
  background-color: #4b4b4ba7;
  -webkit-backdrop-filter: blur(15px);
  backdrop-filter: blur(15px);
  background-size: cover;
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 2.5px;
  border-radius: 10px;
  /* padding: 20px; */
  padding-top: 20px;
  padding-bottom: 20px;
  /* padding-left: 20px; */
  transition: transform 0.3s ease;
}

.paddingLeft {
  padding-left: 20px !important;

}

.cardContent {
  margin-bottom: 10px;
}

.cardContent h3 {
  font-size: 18px;
  margin-bottom: 5px;
}

.cardContent p {
  font-size: 14px;
  color: #777777;
}

.cardContentRow {
  width: 100%;
  display: flex;
  align-items: center;
  /* padding-left: 10px; */
  /* padding-right: 10px; */
}

.cardHeaderCell {
  color: #ffffff;
  /* padding: 4px; */
  display: flex;
  align-items: flex-end;
  border-right: 1px solid #000;
  padding-left: 10px;
  padding-right: 10px;
}

.containerCardHeardCell {
  width: 100%;
  display: flex;
}

.cardContentCell {
  color: #ffffff;
  /* padding: 4px; */
  display: flex;
  align-items: flex-end;
  /* height: 100px; */
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 10px;
  padding-right: 10px;

  background-color: transparent;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.paginationContainer {
  width: 100%;
  position: relative;
  /* bottom: 10px; */
}

.paginationButton,
button {
  background-color: #ffffff;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 5px;
  transition: background-color 0.3s ease;
}

.paginationButton:hover,
button:hover {
  /* background-color: #f5f5f5; */
}

.page-controller {
  font-size: 18px;
}

.filter {
  height: 25px;
  width: 200px;
  display: flex;
}

.form-control {
  margin-left: 10px;
  padding-left: 5px;
  border-radius: 50px;
  border: 0px;
  outline: none;
  width: 100%;
  color: black !important;
}

.pagination {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  color: white;
  height: 40%;
}

.page-controller {
  width: 20px;
  height: 17px;
  display: flex;
}

select {
  border: 1px solid #000000;
  border-radius: 10px;
  padding-left: 0.5%;
  color: black;
}

.paginationButton {
  border-radius: 20px;
}

.page-controller {
  color: #000000;
}

.HeardANDFilter {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 110px;
  z-index: 999;
  /* background-color: rgba(82, 81, 81, 0.691); */
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
}

.navigationContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  padding: 5px;
  /* border-radius: 5px; */

}

.pageTitle {
  color: white;
  /* width: 100%; */
  font-size: x-large;
  margin-bottom: 5px;
}

.divIcon {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  color: white;
  align-items: center;
  background-color: #ffffff00 !important;
}

.divIcon:hover {
  background-color: #ffffff00 !important;
}

.divIcon:focus {
  background-color: #ffffff00 !important;

}

.containerBottonHeard {
  /* width: 100%; */
}

.containerRow {
  width: 100%;
  /* height: 337px; */
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  display: flex;
  max-height: 505px;
  width: 95%;
  max-height: 629px;
  flex-direction: column;
  /* align-items: center; */
  /* justify-content: center; */
  width: 100%;
}

.logoAdd {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  object-fit: contain;
  border-radius: 10px;
  -webkit-border-radius: 10px;
}

.createButton {
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 8px 16px;
  font-size: 14px;
  cursor: pointer;
  margin-top: 5px;
}

.filterContainer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.textAndFilter {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 55%;
  justify-content: space-between;
}

.containerHeardANDTable {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.rowContainer {
  height: 55vh;
  /* overflow-y: hidden; */
}

.containerRow {
    height: 95% !important;
  }

  .containerRow::-webkit-scrollbar-track {
    background-color: rgba(99, 99, 99, 0);
  }

/* Estilos generales del scroll */
::-webkit-scrollbar {
  width: 10px;
  /* background-color: rgba(182, 222, 250, 0.256); */
}

::-webkit-scrollbar-track {
  background-color: rgba(99, 99, 99);
}

::-webkit-scrollbar-thumb {
  background-color: #888;
  /* Color del thumb */
  border-radius: 4px;
  /* Bordes redondeados del thumb */
}

.textNameUserSmall {
  display: none;
}

@media screen and (max-width: 1366px) {
  .container-Table {
    width: 1210px;
    overflow-x: auto;
    overflow-y: hidden;

  }
  .cardContainer {
    width: 1150px;
    max-height: 629px;
  }
}

@media screen and (max-width: 1220px) {
  .container-Table {
    width: 1210px;
    overflow-x: scroll;
  }

  /* .cardContainer {
    width: 1150px;
  } */

}


@media screen and (max-height: 794px) {
  .contenedorgeneral-Table {
    height: 363px;
    overflow-y: hidden;
  }

  .textAndFilter {
    flex-direction: row;
  }

  .navigationContainer {
    flex-direction: column;
    justify-content: space-around;
  }

  @media screen and (max-width: 638px) {
    .filterContainer {
      align-items: end;
      margin-bottom: 19px;
      /* margin-left: 10px; */
    }

    .textAndFilter {
      flex-direction: column;
    }

    .navigationContainer {
      flex-direction: row;
      /* width: 100%; */
      justify-content: space-around;
      align-items: flex-start;
    }

    .pageTitle {
      font-size: 15px;
      display: flex;
      justify-content: center;
      /* padding-left: 5px; */

      /* justify-content: space-between; */
    }
  }

  @media screen and (max-width: 412px) {
    .filterContainer {
      align-items: end;
      /* margin-bottom: 19px; */
      /* margin-left: 20px; */
    }

    .filterContainer {
      width: 100%;
      /* padding-top: 0px; */
    }

    .createButton {
      margin-top: 0px;
      padding: 5px;
    }

    .containerFilter {
      width: 100%;
    }

    .navigationContainer {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 5px;
      /* border-radius: 5px; */

    }

    .pagination {
      /* margin-top: -25px; */
      flex-direction: column-reverse;
    }

    .containerRow {
      /* height: 400px; */
      height: calc(100vh - 200px);

    }

    .filter {
      width: 100%;
      display: flex;
      justify-content: center;
    }

    .form-control {
      width: 80px;
      /* padding-left: 2px; */
    }

    .textAndFilter {
      width: 70%;
      flex-direction: column;
    }
  }

  /* Estilos generales del scroll */
  ::-webkit-scrollbar {
    width: 10px;
    /* background-color: rgba(182, 222, 250, 0.256); */
  }

  ::-webkit-scrollbar-track {
    background-color: rgba(99, 99, 99);
  }

  ::-webkit-scrollbar-thumb {
    background-color: #888;
    /* Color del thumb */
    border-radius: 4px;
    /* Bordes redondeados del thumb */
  }

  @media screen and (max-height: 600px) {
    .containerRow {
      /* height: 400px; */
      height: calc(100vh - 350px);
  
    }

    .paginationContainer{
      height: 100px;
      overflow-y: scroll;
    }
  }

  @media screen and (min-height: 700px) {
    .containerRow {
      /* height: 400px; */
      height: calc(100vh - 500px);

    }
  }

}

@media screen and (max-height: 344px) {
  .textNameUser{
    display: none;
  }

  .textNameUserSmall{
    display: block;
  }

  .paginationContainer{
    height: 81px;
  }

  .contenedorgeneral-Table{
    margin-top: 7px;
  }

  .rowContainer {
    height: 144px;
  }

  .containerRow {
    height: 70px;
  }
}

@media screen and (min-height: 600px) {
  .containerRow {
    height: 250px;
  }
}



@media screen and (max-width: 1366px) {
  .rowContainer {
    height: 332px;
  }
}