.containerLogo {
    margin-top: 10px;
    display: flex;
    justify-content: center;
}

.buttonState {
    font-family: monospace;
    color: #000000;
    border: none;
    border-radius: 20px;
    width: 80px;
    height: 40px;
    transition: .3s;
}

.buttonState:hover {
    background-color: #f63b3b;
    box-shadow: 0 0 0 5px #f63b3b5f;
    color: #ff0000;
    cursor: pointer;
}

.logo2 {
    margin-bottom: 30px;
    width: 100%;
}

.iconPdf {
    cursor: pointer;
    color: rgb(197, 0, 0);
    transition: 0.5s;
}

.iconPdf:hover {
    color: rgb(255, 0, 0);
    transform: scale(2);
    box-shadow: 0 0 10px 0 rgb(255, 0, 0) inset, 0 0 10px 4px rgb(255, 0, 0);
}

.iconDuplicate {
    cursor: pointer;
    color: rgb(197, 102, 0);
    transition: 0.5s;
}

.iconDuplicate:hover {
    /* color: rgb(255, 0, 0); */
    transform: scale(2);
    box-shadow: 0 0 10px 0 rgb(255, 132, 0) inset, 0 0 10px 4px rgb(255, 132, 0);
}

.iconEdit {
    cursor: pointer;
    color: rgb(79, 244, 170);
    transition: 0.5s;
}

.iconEdit:hover {
    color: rgb(0, 255, 140);
    transform: scale(2);
    box-shadow: 0 0 10px 0 rgb(3, 192, 107) inset, 0 0 10px 4px rgb(3, 192, 107);
}

.iconPhoto {
    cursor: pointer;
    color: rgb(0, 217, 255);
    transition: 0.5s;
}

.iconPhoto:hover {
    transform: scale(1.5);
    box-shadow: 0 0 10px 0 rgb(2, 71, 83)inset, 0 0 10px 4px rgb(2, 71, 83);
}

.iconsPdf {
    display: flex;
    flex-direction: row;
}

.ContainerTable {
    width: 100%;
}

.swal2-popup {
    width: auto;
    height: auto;
    /* color: none !important; */
    /* background-color: #a9a9a9f7 !important; */
}

.pPdf {
    color: transparent;
    width: 1px;
    height: 1px;
}


.tablePdf1 {
    width: 500px;
    height: 100px;
    border: 0px solid #000000 !important;
    /* margin-right: 10px; */
}

.thPdfClient {
    background-color: rgb(57, 58, 59);
    text-align: start;
}

.thPdfNameClient {
    width: 678px;
    text-align: start;
}

.pdfLogo {
    width: 418px;
    display: flex;
    justify-content: start;
}


.lineaAzul {
    position: absolute;
    border-top: 2px solid blue;
    /* height: 3696px; */
    width: 100%;
    left: 40px;
    /* bottom: 3696px; */
    /* top: 3696px; */
    top: 0;
    /* bottom: 3696px; */

}


.photo {
    width: 100%;
    height: 100%;
}

@supports(object-fit: cover) {
    .photo {
        object-fit: cover;
        object-position: center center;
    }
}

.containerTablePdf {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 10px;
    box-sizing: border-box;
}

.subContainerTablePdf {
    display: flex;
    flex-direction: column;
    width: 100%;
}





.specificationsPdfExtra {
    width: 281px;
    height: 28px;
    background: rgb(105, 105, 106);
    color: rgb(255, 255, 255);
    border-right: 1px solid #000000 !important;
    border-top: 1px solid #000000 !important;
    display: flex;
    align-items: center;
    justify-content: center;
}



@media screen and (max-width: 1164px) {

    .specificationsPdfExtra {
        width: 225.5px;
        height: 34px;
        background: rgb(105, 105, 106);
        color: rgb(255, 255, 255);
        border-right: 1px solid #000000 !important;
        border-top: 1px solid #000000 !important;
        display: flex;
        align-items: center;
        justify-content: center;
    }


}
