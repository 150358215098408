/* .containerGeneralProfile{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
} */

.logo {
  margin-bottom: 30px;
}

.containerGifAndLogin{
  display: flex;
  width: 100%;
}

.gifValue{
  width: 100%;
  border-radius: 5px;
}

.containerLoginRight{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}

.containerMainLogin{
  display: flex;
  flex-direction: column;
  width: 90%;
  align-items: center;
  justify-content: center;
}

.forgetPassword{
  width: 40%;
  display: flex;
  justify-content: flex-end;
  font-size: 15px;
  cursor: pointer;
  color: #93a7f3;
  transition: ease-in-out 0.3s;
}

.forgetPassword:hover{
  color: #0033db;
  transform: scale(1.2);
}

.whatsappApi{
  width: 200px;
  text-decoration: none;
  display: flex;
  justify-content: end;
}

.iconWhatsapp{
  margin-left: 5px;
  padding-top: 1px;
}

.videoTag{
  width: 100%;
}

.textAppcotizaLoginFooter{
  display: flex;
  width: 100%;
  color: white;
  justify-content: center;
  margin-top: 20px;
}

.watchVideo{
  cursor: pointer;
  color: #93a7f3;
  margin-left: 10px;
  transition: ease-in-out 0.3s;
}

.watchVideo:hover{
  color: #0033db;
  transform: scale(1.2);
}

.containerVideo {
  width: 700px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  flex-direction: column;
}

.drawerFormLogin {
  /* margin-bottom:100px; */
  /* background-color: rgba(0, 0, 0, 0.3); */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(14px);
  width: 340px;
  border-radius: 10px;
  box-shadow: 15px 10px 10px black;
  text-align: center;
  color: white;
}


.ContainerProfile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.logoAppCotiza{
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  object-fit: contain;
  border-radius: 10px;
  -webkit-border-radius: 10px; /* Prefijo para Safari */
}

.ContainerCompany {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  overflow-y: auto;
  overflow: hidden;
}

.ContainerPassword {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.inputExteriorPasswordLeft {
  height: 38px;
  width: 215px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

.inputExteriorPasswordRight {
  height: 38px;
  width: 40px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

.inputProfile {
  background-image: url(../Assest/user.png);
  height: 38px;
  width: 240px;
  margin-top: 15px;
  margin-bottom: 10px;
  border-radius: 6px;
  padding-left: 15px;
  border: none;
  /* border: 2px solid #000000; */
  /* background-color: transparent; */
  color: rgb(0, 0, 0);
  background-repeat: no-repeat;
  background-size: 10%;
  background-position: 210px;
  outline: none;
  transition: 0.2s;
}

.inputProfile:hover {
  box-shadow: 0 0 10px 0 #db3434 inset, 0 0 10px 4px #db3434;
}

.inputProfileLogin {
  background-image: url(../Assest/user.png);
  height: 38px;
  width: 240px;
  margin-top: 15px;
  margin-bottom: 10px;
  border-radius: 6px;
  padding-left: 15px;
  border: none;
  /* border: 2px solid #000000; */
  /* background-color: transparent; */
  color: rgb(0, 0, 0) !important;
  background-repeat: no-repeat;
  background-size: 10%;
  background-position: 210px;
  outline: none;
  transition: 0.2s;
}

.inputProfileLogin:hover {
  box-shadow: 0 0 10px 0 #db3434 inset, 0 0 10px 4px #db3434;
}

.inputPassword {
  height: 38px;
  width: 240px;
  border-radius: 6px;
  padding-left: 15px;
  border: none;
  /* border: 2px solid #000000; */
  /* background-color: transparent; */
  color: rgb(0, 0, 0);
  background-repeat: no-repeat;
  background-size: 10%;
  background-position: 210px;
  outline: none;
  transition: 0.2s;
}

.inputPassword:hover {
  box-shadow: 0 0 10px 0 #db3434 inset, 0 0 10px 4px #db3434;
}

.inputPasswordLogin {
  height: 38px;
  width: 240px;
  border-radius: 6px;
  padding-left: 15px;
  border: none;
  /* border: 2px solid #000000; */
  /* background-color: transparent; */
  color: rgb(0, 0, 0) !important;
  background-repeat: no-repeat;
  background-size: 10%;
  background-position: 210px;
  outline: none;
  transition: 0.2s;
}

.inputPasswordLogin:hover {
  box-shadow: 0 0 10px 0 #db3434 inset, 0 0 10px 4px #db3434;
}

.Textregister {
  text-decoration: none;
  display: flex;
  box-sizing: border-box;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 35px;
  width: 120px;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: transparent;
  border: 2px solid;
  border-radius: 20px;
  cursor: pointer;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1;
  margin: 20px;
  padding: 1.2em 2.8em;
  text-decoration: none;
  text-align: center;
  text-transform: uppercase;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
}

.button:disabled{
  background-color: rgba(174, 174, 174, 0.618);
}

.button:hover,
:focus {
  color: black;
  outline: 0;
}

.buttonQuotes {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 35px;
  width: 150px;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: transparent;
  border: 2px solid;
  border-radius: 20px;
  cursor: pointer;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1;
  margin: 20px;
  padding: 1.2em 2.8em;
  text-decoration: none;
  text-align: center;
  text-transform: uppercase;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
}

.buttonQuotes:hover,
:focus {
  color: rgb(255, 255, 255);
  outline: 0;
}

.password {
  height: 30px;
  width: 30px;
  margin-left: -11px;
  margin-top: 3px;
  cursor: pointer;
}

.password:hover {
  opacity: 0.7;
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
  margin-bottom: 10px;
}

.neon_EffectLogin {
  border-color: #e74c3c;
  color: white;
  box-shadow: 0 0 40px 40px #db3434 inset, 0 0 0 0 #e74c3c;
}

.neon_EffectLogin:hover {
  box-shadow: 0 0 10px 0 #db3434 inset, 0 0 10px 4px #db3434;
}

.neon_EffectLogin2 {
  border-color: #6e6d6d;
  color: white;
  box-shadow: 0 0 40px 40px #434242 inset, 0 0 0 0 #6e6d6d;
}

.neon_EffectLogin2:hover {
  box-shadow: 0 0 10px 0 #434242 inset, 0 0 10px 4px #6e6d6d;
}

.forgetPassword2{
  width: 100%;
  display: flex;
  justify-content: flex-end;
  font-size: 15px;
  cursor: pointer;
  color: #93a7f3;
  transition: ease-in-out 0.3s;
  margin-bottom: 7px;
}

.forgetPassword2:hover{
  color: #0033db;
  transform: scale(1.2);
}

@media screen and (max-width: 412px) {
  .button {
    height: 20px;
    width: 105px;
  }

  .drawerFormLogin {
    width: 100%;
  }

  .Textregister {
    justify-content: center;
  }
}