  .titlesBill{
    display: flex;
    justify-content: space-around;
    width: 100%;
  }
  .titlesBill p{
    text-align: center;
  }

  ul{
    list-style: none;
  }

  .contactInfoBill{
    display: flex;
    height: 150px;
    width: 100%;
    gap: 150px;
    margin-bottom: 70px;
  }

  li{
    text-align: center;
    height: 40px;
  }
  .valuesTableBill{
    display: flex;
    justify-content: space-between;
    /* height: 200px; */
    width: 100%;

  }
  table{
    border-collapse: collapse;
    width: 100%;

  }
  td{
    border: 1px solid black;
    text-align: center;
  font-size: medium;
  justify-content: end;
  }
  .lineFirmBill{
    display: flex;
    justify-content: left;
    border-top: 1px solid black;
    height: 2px;
    max-width: 200px;

    align-items: center;

  
  
  }
  .logoBill{
    display: flex;
    justify-content: left;
    height: 50px;
    width: 50px;
    padding: 0;
  }
  .titleContactBill{
    display: flex;
    flex-direction: column;
  
  
    
  }
  .dataContactBill{
    display: flex;
    flex-direction: column;
  
    justify-content: flex-start;
  
  }
  .dataContactBill li{
    display: flex;
    list-style: none;
  }

  .titleContactBill li{
    text-align: left;
    list-style: none;
    margin-bottom: 10px;
  }
  .titleContactBill ul{
    display: flex;
    flex-direction: column;
  }
  .dataContactBill li{
    /* text-decoration: underline; */
    width: 400px;
    padding-left: 40%;
    border-bottom: 1px solid black;
    text-align: left;
    margin-bottom: 10px;
  }
  .accountInfoBill{
    display: flex;
    justify-content: center;
  flex-direction: column;
    height: 200px;
    width: 100%;
  }
  .accountInfoBill p{
    display: flex;
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .firmsBill{
    display: flex;
    /* justify-content: space-around; */
    flex-direction: column;
    height: 200px;
    width: 100%;
  }
  .firmsBill p{
    display: flex;
    margin: 0px;
  }

  .allBill{
    width: 900px;
    background-color: white;
    height: 100vh;
  }

  .mainBillPage{
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    background-color: white;
  }

  .signatureBill{
    /* height: 80px; */
    width: 200px;
  }

  @media only screen and (max-width: 768px) {
    .allBill{
      overflow-x: scroll;
      padding-left: 20px;
      padding-right: 20px;
    }

    .mainBillPage{
      width: 900px !important;
    }
  }