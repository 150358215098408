.containerProductoIn3djsGenerate{
    width: 100%;
    background-color: white;
}

/* canvas{
    width: 100% !important;
    height: 100% !important;
}  */

.canvasThreejsContainerGeneral{
    display: flex;
    flex-direction: row-reverse;

}

.mainOptions{
    background-color: aqua;
}

.swal2-input{
    background-color: #868686 !important;
    color: white !important;
}

.swal2-input:focus{
    background-color: #868686 !important;
    color: white !important;
}

.swal2-popup{
    background-color: #e0e0e0 !important;
    color: black !important;
}

.swal2-popup:focus{
    background-color: #e0e0e0 !important;
    color: black !important;
}

.predefinedCategory{
    bottom: 0;
    display: flex;
    flex-direction: column;
    position: fixed;
    width: 20%;
    /* height: calc(100vh - 100px); */
    height: 100%;
    align-items: center;
    text-align: center;
    color: white;
    /* overflow-y: scroll; */
    backdrop-filter: blur(5px);
    justify-content: flex-start;
    /* justify-content: center; */
}

.margin-top-pixel{
    margin-top: 100px;
}

.controlPanel{
    right: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    position: fixed;
    width: 15%;
    height: 100vh;
    align-items: center;
    text-align: center;
    color: white;
    overflow: auto;
    backdrop-filter: blur(5px);
}

.imagePreviewModel{
    width: 100%;
    background-size: cover;
    cursor: pointer;
}

.containerOptions{
    display: flex;
    flex-direction: column;
    width: 15%;
}

.textureContainer{
    display: flex;
    flex-direction: column;
}

.optionNotView{
    z-index: 3;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
    font-size: 24px;
    position: absolute;
    box-sizing: border-box;
    width: 20%;
    backdrop-filter: blur(3px);
}

.faBarsIconView{
    height: 40px;
    width: 40px;
    display: block;
    color: rgb(255, 255, 255);
  }

.containerImages{
    /* overflow: hidden; */
    display: flex;
    justify-content: center;
    align-items: center;
    /* height: 200px; */
    width: 100%;
    flex-direction: column;
}

.Mesurement{
    display: none;
    position: fixed;
    right: 30%;
    top: 166px;
    width: 229px;
    padding-bottom: 10px;
    /* height: 166px; */
    background-color: #58565662;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
}

.Long1{
    display: none;
    position: fixed;
    right: 500px;
    top: 166px;
    width: 229px;
    height: 166px;
    background-color: #58565662;
    backdrop-filter: blur(5px);
}
.Long2{
    display: none;
    position: fixed;
    right: 500px;
    top: 166px;
    width: 229px;
    height: 166px;
    background-color: #58565662;
    backdrop-filter: blur(5px);
}
.Width1{
    display: none;
    position: relative;
    /* right: 500px;
    top: 166px; */
    left:45%;
    bottom: 430px;
    width: 229px;
    height: 290px;
    background-color: #58565662;
    backdrop-filter: blur(5px);
    border-radius: 5px;
}
.Width2{
    display: none;
    position: fixed;
    right: 500px;
    top: 166px;
    width: 229px;
    height: 166px;
    background-color: #58565662;
    backdrop-filter: blur(5px);
}
.LongWell{
    display: none;
    position: fixed;
    right: 500px;
    top: 166px;
    width: 229px;
    height: 166px;
    background-color: #58565662;
    backdrop-filter: blur(5px);
}
.WidthWell{
    display: none;
    position: fixed;
    right: 500px;
    top: 166px;
    width: 229px;
    height: 166px;
    background-color: #58565662;
    backdrop-filter: blur(5px);
}
.WidthWell2{
    display: none;
    position: fixed;
    right: 500px;
    top: 166px;
    width: 229px;
    height: 166px;
    background-color: #58565662;
    backdrop-filter: blur(5px);
}
.LongWell2{
    display: none;
    position: fixed;
    right: 500px;
    top: 166px;
    width: 229px;
    height: 166px;
    background-color: #58565662;
    backdrop-filter: blur(5px);
}
.Regrown{
    display: none;
    position: fixed;
    right: 500px;
    top: 166px;
    width: 229px;
    height: 290px;
    background-color: #58565662;
    backdrop-filter: blur(5px);
}
.SplashBack{
    display: none;
    position: fixed;
    right: 500px;
    top: 166px;
    width: 229px;
    height: 290px;
    background-color: #58565662;
    backdrop-filter: blur(5px);
}

.distanceX2{
    display: none;
    position: fixed;
    right: 500px;
    top: 166px;
    width: 229px;
    height: 166px;
    background-color: #58565662;
    backdrop-filter: blur(5px);
}

.distanceY2{
    display: none;
    position: fixed;
    right: 500px;
    top: 166px;
    width: 229px;
    height: 166px;
    background-color: #58565662;
    backdrop-filter: blur(5px);
}

.distanceX{
    display: none;
    position: fixed;
    right: 500px;
    top: 166px;
    width: 229px;
    height: 166px;
    background-color: #58565662;
    backdrop-filter: blur(5px);
}

.distanceY{
    display: none;
    position: fixed;
    right: 500px;
    top: 166px;
    width: 229px;
    height: 166px;
    background-color: #58565662;
    backdrop-filter: blur(5px);
}

.form__labelMeasure {
    font-family: 'Roboto', sans-serif;
    font-size: 1.2rem;
    margin-left: 2rem;
    margin-top: 0.7rem;
    display: block;
    transition: all 0.3s;
    transform: translateY(0rem);
  }
  
  .form__inputMeasure {
    height: 14px;
    width: 125px;
    font-family: 'Roboto', sans-serif;
    color: #333;
    font-size: 1.2rem;
      margin: 0 auto;
    padding: 1.5rem 2rem;
    border-radius: 0.2rem;
    background-color: rgb(255, 255, 255);
    border: none;
    display: block;
    border-bottom: 0.3rem solid transparent;
    transition: all 0.3s;
  }
  
  .form__inputMeasure:placeholder-shown + .form__labelMeasure {
    opacity: 0;
    visibility: hidden;
    -webkit-transform: translateY(-4rem);
    transform: translateY(-4rem);
  }
  
.form__groupMeasure{
    margin-top: 18px;
}

.textureText{
    cursor: pointer;
    transition: transform 0.5s ease-in-out;
}

.textureText:hover{
    color: red;

}

.textureImageDimensions{
    /* padding-top: 5px; */
    width: 45px;
    height: 45px;
    background-size: cover;
}

.styleButtonsOptions{
    background-color: rgb(82, 82, 82);
    border-radius: 10px;
    text-decoration: none;
    color: white;
    margin-bottom: 10px;
    width: 85%;
    border: 1px #2b2b2b solid;
    height: 30px;
}

.ivaSelect{
    background-color: #868686;
    color: white;
    width: 40%;
}

.ivaSelect:focus{
    background-color: #868686;
    color: white;
    width: 40%;
}

.styleButtonsOptions:hover{
    background-color: rgb(154, 153, 153);
    color: rgb(0, 0, 0);

}

.styleButtonsOptions:focus{
    background-color: rgb(82, 82, 82);
    border-radius: 10px;
    text-decoration: none;
    color: white;  
    margin-bottom: 10px;

}

.marginZero{
    margin-top: 0px;
}

.containerResponseOptions{
    width: 100%;
    overflow-y: scroll;
    padding-bottom: 10px;
    padding-top: 10px;
    height: 70%;
}

@media screen and (max-width: 412px) {
    .predefinedCategory{
        width: 40%;
        font-size: 15px;
    }

    .optionNotView{
        width: 40%;
    }

    .Width1{
        left: 30%;
    }
}

@media screen and (max-width: 464px) {
    .swal2-input{
        width: 140px !important;
    }
}

@media screen and (max-width: 620px) {
    .Width1{
        left: 40%;
        width: 160px;
    }
}
