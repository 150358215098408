.inputAddCompany{
    background-image: url(../Assest/company.png);
    height: 38px;
    width: 240px;
    margin-top: 15px;
    margin-bottom: 10px;
    border-radius: 6px;
    padding-left: 15px;
    border: none;
    /* border: 2px solid #000000; */
    /* background-color: transparent; */
    color: rgb(0, 0, 0);
    background-repeat: no-repeat;
    background-size:10%;
    background-position: 210px;
    outline: none;
    transition: 0.2s;
}

.inputAddCompany:hover{
    box-shadow: 0 0 10px 0 #db3434 inset, 0 0 10px 4px #db3434;
}

.containerDrawFormsCompany{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: calc(100vh - 110px);
}

.drawerFormRegisterCompany{
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    color: white;
    /* align-items: center; */
    -webkit-backdrop-filter: blur(4px);
    backdrop-filter: blur(4px);
    width: 80%;
    border-radius: 10px;
    box-shadow: 15px 10px 10px black;
    padding-top: 10px;
    padding-bottom: 10px;
}

.personalInformation{
    color: white;
}

.ContainerConfig{
    margin-left: 10px;
    margin-right: 10%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: turquoise;
}

.ContainerCompanyAddForm{
    margin-top: 80px;
    margin-bottom: 40px;
    width: 80%;
    display: flex;
    justify-content: center;
}

.password3{
    left: 160px;
    position: absolute;
    height: 30px;
    width: 30px;
    bottom: 15px;
    cursor: pointer;
    z-index: 9999;
}

.password3:hover{
    opacity: 0.7;
}

.password4{
    left: 219px;
    position: absolute;
    height: 30px;
    width: 30px;
    bottom: 15px;
    cursor: pointer;
    z-index: 9999;
}

.password4:hover{
    opacity: 0.7;
}

option{
    color: black;
}

@media screen and (max-width: 1120px){
    .ContainerCompanyAddForm{
        /* margin-top: 100px; */
        margin-bottom: 10px;
        width: 90%;
    }
}

@media screen and (max-width: 700px){
    .ContainerCompanyAddForm{
        /* margin-top: 200px; */
        margin-bottom: 10px;
        width: 80%;
        
    }
    .inputPasswordContainer label {
        position: absolute;
        /* bottom: 50px; */
        left: 10px;
        font-size: 16px;
        color: #aaa;
        transition: all 0.3s ease;
        pointer-events: none;
    }
    .inputPasswordContainer .inputPasswordText:focus + label,
    .inputPasswordContainer .inputPasswordText:not(:placeholder-shown) + label {
        bottom: 50px;
        left: 5px;
        font-size: 12px;
        color: #007bff;
    }
}

