canvas {
    background-color: white; /* Cambia 'white' al color de fondo que desees */
}

.ContainerTable {
    width: 100%;
}


.containerLogoPdf {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 240px;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: 35px;
    box-sizing: border-box;
    justify-content: space-between;
}

.containerTableLogoPdf {
    display: flex;
    flex-direction: column;
    width: 57%;
    align-items: end;
}

.clientPdf {
    border: 1px solid #000000;
    /* height: 145px; */
    width: 40%;
    text-align: start;
    padding-left: 5px;
    padding-bottom: 10px;
    font-size: 20px;
    box-sizing: border-box;
}

.clientPdf10 {
    border: 1px solid #000000;
    /* height: 145px; */
    width: 60%;
    text-align: start;
    padding-left: 5px;
    padding-bottom: 10px;
    font-size: 20px;
    box-sizing: border-box;
}

.clientPdf2 {
    border: 1px solid #000000;
    height: 70px;
    width: 40%;
    text-align: start;
    padding-left: 5px;
    box-sizing: border-box;
    font-size: 18px;
}

.clientPdf3 {
    border: 1px solid #000000;
    height: 70px;
    width: 60%;
    text-align: start;
    padding-left: 5px;
    box-sizing: border-box;
    font-size: 18px;
}

.containerClientPdf {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 170px;
}

.containerClientPdf2 {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.containerGeneralPdf {
    width: 1400px;
    display: flex;
    flex-direction: column;
    background-color: white;
    color: black;
    transform-origin: center top;
}

.lineaAzul {
    position: absolute;
    border-top: 2px solid blue;
    /* height: 3696px; */
    width: 100%;
    left: 40px;
    /* bottom: 3696px; */
    /* top: 3696px; */
    top: 0;
    /* bottom: 3696px; */

}

.divSeparation {
    width: 100%;
    /* height: 0.1; */
    border-bottom: 2px solid rgba(0, 0, 0, 0.274);
    /* border-color: black; */
    /* border-radius: 80%; */
    /* margin-top: 32px; */
    /* margin-bottom: 32px; */
}

.buttonContainerPdfTop{
    display: flex;
    width: 100px;
    flex-direction: row;
    justify-content: flex-start;
    height: 80px;
    position: fixed;
    margin-top: 10px;
    margin-left: 5px;
}

.buttonContainerPdf {
    display: flex;
    width: 100vw;
    flex-direction: row;
    justify-content: center;
    height: 80px;
    top: calc(100vh - 160px);
    /* padding-top: 20px; */
    /* padding-bottom: 20px; */
    /* min-width: 1100px; */
    position: fixed;
    /* bottom: 5px; */
    /* background-color: rgba(225, 225, 225, 0.371); */
}

.mainContainerPdf{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    
}

.containerScrollPdf{
    width: 100%;
    /* height: calc(100vh - 120px); */
    overflow: scroll;
}

.containerHeaderPdf {
    height: 354px;
}

.overallContainerPdf {
    display: flex;
    justify-content: center;
    align-items: start;
    flex-direction: column;
    width: 1400px;
    background-color: white;
    /* height: 100%; */
    align-items: center;
    padding-left: 25px;
    padding-right: 25px;
}

.containerImageLogo {
    width: 40%;
    /* padding-right: 10%; */
    height: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
}

.textDetail {
    text-align: center;
    font-size: x-large;
    color: #000000;
}

.itemNumberPdf {
    background: rgb(239, 239, 239);
    color: rgb(0, 0, 0);
    border-left: 1px solid #000000 !important;
    border-bottom: 1px solid #000000 !important;
    border-right: 1px solid #000000 !important;
    height: 86px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
}

.itemNumberPdfDescription {
    background: rgb(239, 239, 239);
    color: rgb(0, 0, 0);
    border-left: 1px solid #000000 !important;
    border-bottom: 1px solid #000000 !important;
    border-right: 1px solid #000000 !important;
    height: 86px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* font-size: 20px; */
    font-size: 1em;
}

.itemNumberPdfHeard {
    background: rgb(105, 105, 106);
    color: rgb(255, 255, 255);
    border-left: 1px solid #000000 !important;
    border-top: 1px solid #000000 !important;
    border-right: 1px solid #000000 !important;
    border-bottom: 1px solid #000000 !important;
    height: 28px;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.itemNumberPdfHeardDescription {
    background: rgb(105, 105, 106);
    color: rgb(255, 255, 255);
    border-left: 1px solid #000000 !important;
    border-top: 1px solid #000000 !important;
    border-right: 1px solid #000000 !important;
    border-bottom: 1px solid #000000 !important;
    height: 28px;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.tableItemPdf3 {
    width: 10%;
}

.tableItemPdfDescription {
    width: 20%;
}

.paragraphInfoClient {
    margin: 7px;
}

.formSpecificationsPdf {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.tableItemPdf4 {
    width: 100%;
}

.containerItemValue {
    width: 90%;
    margin-bottom: 10px;

}

.containerItem {
    box-sizing: border-box;
    display: flex;
    width: 100%;
}

.containerFoooter{
    box-sizing: border-box;
    height: 515px;
}

.specificationsPdf {
    width: 21.6%;
    height: 28px;
    background: rgb(105, 105, 106);
    color: rgb(255, 255, 255);
    border-right: 1px solid #000000 !important;
    border-top: 1px solid #000000 !important;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
}

.specificationsPdfBody {
    width: 20.6%;
    height: 28px;
    background: rgb(239, 239, 239);
    color: rgb(0, 0, 0);
    border-right: 1px solid #000000 !important;
    border-top: 1px solid #000000 !important;
    display: flex;
    align-items: center;
    font-size: 20px;
    justify-content: center;
}

.specificationsPdfTotal {
    width: 25%;
    height: 28px;
    background: rgb(105, 105, 106);
    color: rgb(255, 255, 255);
    border-right: 1px solid #000000 !important;
    border-top: 1px solid #000000 !important;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
}

.specificationsPdfTotalBody {
    width: 25%;
    height: 28px;
    background: rgb(239, 239, 239);
    color: rgb(0, 0, 0);
    border-right: 1px solid #000;
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;
    display: flex;
    font-size: 20px;
    align-items: center;
    justify-content: center;
}

.observatioSpecifications {
    width: 50%;
    font-size: 20px;
    height: 32.4px;
    background: rgb(239, 239, 239);
    color: rgb(0, 0, 0);
    border-right: 1px solid #000;
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;
    border-left: 1px solid #000;
    display: flex;
    align-items: center;
    padding-left: 10px;
}

.ContainerTablePdfObservation {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 50px;
    margin-top: 5px;
    box-sizing: border-box;
}

.observation {
    font-size: x-large;
    padding-left: 5px;
    width: 55%;
    height: 168px;
    border: 1px solid #000000;
    border-right: 1px solid black;
    font-size: 20px;
    word-wrap: break-word;

}

.containerFirma {
    width: 100%;
    padding-left: 30px;
    padding-right: 30px;
    box-sizing: border-box;
}

.firma {
    display: flex;
    flex-direction: row;
    width: 100%;
    font-size: 20px;
}

.textFirma {
    width: 50%;
    color: #000;
    display: flex;
    justify-content: center;
}

.textFirmaLine {
    width: 50%;
    color: #000000;
    display: flex;
    justify-content: center;
}

.textDescription {
    text-align: center;
    width: 100%;
    font-size: 17px;
    margin: 3px;
}

.addPhotoModel3d {
    border: 2px solid #000000;
    box-sizing: border-box;
    width: 80%;
    height: 463px;
    margin-bottom: 10px;
    /* display: flex;
    justify-content: center;
    align-items: center; */
    overflow: hidden;
    background-color: rgb(255, 255, 255);
}

.imgArr{
    height: 463px;
    width: auto;
}

.addPhotoModel2d {
    /* border: 2px solid #000000; */
    box-sizing: border-box;
    width: 100%;
    height: 890px;
    display: flex;
    justify-content: center;
    /* align-items: center; */
    margin-bottom: 110px;
}

@media screen and (max-width: 1164px) {



}

.ContainerTablePdfObservation {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    /* margin-left: 155px; */
    margin-bottom: 50px;
    margin-top: 19px;

}

.tablePdf7 {
    width: 45%;
}

.containerFirma {
    width: 100%;
}

.firma {
    display: flex;
    flex-direction: row;
    width: 100%;
}

/* .textDescription{
    text-align: center;
    width: 100%;
} */

.containerTextDescription {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.image3d {
    width: 1400px;
    position: relative;
    /* right: 284px; */
    /* bottom: 110px; */
    right: 210px;
    bottom: 48px;
    transform: scale(0.9);
}

.image3dMobile {
    width: 100%;
    /* position: relative; */
    /* right: 284px; */
    /* bottom: 110px; */
    /* right: 144px; */
    /* bottom: 99px; */
    /* transform: scale(0.7); */
}

.image2d {
    width: 135%;
    position: relative;
    left: -120px;
    bottom: 30px;
}

.secondConatinerModel2d {
    height: 890px;
    width: 80%;
    border: 2px solid #000000;
    overflow: hidden;
    background-color: white;
    box-sizing: border-box;
}

.secondConatinerModel3d{
    width: 100%;
    display: flex;
    justify-content: center;
    box-sizing: border-box;
}
