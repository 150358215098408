body {
  margin: 0;
  background-image: url(./Assest/Fondo5.jpeg);
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #000000;
  background-position: center;
  min-height: 100vh;
  background-repeat: repeat-y;
  background-size: cover;
}

#root {
  display: flex;
  flex-direction: column;
  /* background-color: white; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
  scroll-behavior: smooth;
  background-color: white;
}

.overallContainer {
  display: flex;
  /* justify-content: center; */
  /* align-items: center; */
  flex-direction: row;
  /* padding-top: 10px; */
  height: calc(100vh - 110px);
  /* padding-bottom: 100px; */
  box-sizing: border-box;
  /* width: 100%; */
  /* height: 100%; */
  overflow: hidden;
}

.overallContainerAdd{
  display: flex;
  /* justify-content: center; */
  /* align-items: center; */
  flex-direction: row;
  /* padding-top: 10px; */
  height: 100vh;
  /* padding-bottom: 100px; */
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  /* overflow: hidden;*/
}

.overallContainerUsers {
  display: flex;
  /* justify-content: center; */
  /* align-items: center; */
  flex-direction: row;
  /* padding-top: 10px; */
  /* height: calc(100vh - 110px); */
  /* padding-bottom: 100px; */
  box-sizing: border-box;
  /* width: 100%; */
  /* height: 100%; */
  overflow: hidden;
}

.overallContainerLogin {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  padding-top: 10px;
  height: calc(100vh - 110px);
  box-sizing: border-box;
  /* background-image: url("./Assest/Fondo5.jpeg"); */

  /* overflow: hidden; */
}

.imageBackground {
  background-image: url("./Assest/Fondo5.jpeg") !important;
  margin: 0;
  min-height: 100vh;
  background-position: center;
  background-repeat: repeat-y;
  background-size: cover;
}


input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="datetime-local"]::-webkit-inner-spin-button,
input[type="datetime-local"]::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}


.closeButton {
  text-decoration: none;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: center;
  color: white;
  /* padding-right: 10px; */
}

.backgroundLoader {
  position: fixed;
  z-index: 9999;
  height: 100%;
  width: 100%;
  background-color: #1e1e1e75;
}

.options {
  display: flex;
  flex-direction: row;
}

.newCompany {
  margin: 15px;
  cursor: pointer;
}

.newCompany:hover {
  box-shadow: 0 0 10px 0 #db3434 inset, 0 0 10px 4px #db3434;
}

.linkNewCompany {
  text-decoration: none;
  color: white;
}


.oldCompany {
  margin: 15px;
  cursor: pointer;
}

.Obligatory {
  font-size: 15px !important;
  color: #fa5757c4;
}

.oldCompany:hover {
  box-shadow: 0 0 10px 0 #db3434 inset, 0 0 10px 4px #db3434;
}

@media screen and (max-height: 620px) {
  .overallContainerLogin {
    height: calc(100vh + 50px);
  }
}



@media screen and (max-height: 423px) {
  .overallContainerLogin {
    height: calc(100vh + 240px);
    overflow-y: scroll;
  }
}

@media screen and (max-width: 425px) {
  .overallContainerLogin {
    width: 100%;
  }
}

/* .body::-webkit-scrollbar{
  height: 0px;
  width: 7px;
}

.body::-webkit-scrollbar-thumb{
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 20px;
  border: 2px solid rgba(0, 0, 0, 0.3);
}

.body::-webkit-scrollbar-track{
  backdrop-filter: blur(4px);
  background-color: rgba(0, 0, 0, 0.3);
} */

/* @media screen and (max-width: 647px){
  body{
    height: 180vh;
  }
  .overallContainer{
    width: 123%;
  }
} */