.canvasModelPlans {
    background-color: white;
    height: auto;
    width: auto;
    /* transform: scale(0.5); */
}

.canvasModelPlansPdf{
    background-color: white;
    height: auto;
    width: auto;
}

.containerCanvas {
    /* height: 1100px;
    width: 100%; */
    height: 2000px;
    width: 1490px;
    background-color: white;
}

.quitButton {
    text-decoration: none;
    /* position: absolute;
    top: 1000px;
    left: 600px;
    transform: translate(-50%, 50%);     */
}

.saveButton {
    text-decoration: none;
    /* position: absolute;
    top: 1000px;
    left: 800px;
    transform: translate(-50%, 50%); */
}

.TextPlans{
    text-decoration: none;
    position: fixed;
    bottom: 0;
    right: 130px;
}

.TextPlans2 {
    text-decoration: none;
    position: fixed;
    bottom: 0;
    right: 0;
}

.buttonLeftZoom{
    width: 50px;
    border-bottom-left-radius: 20px;
    border-top-left-radius: 20px;
    border-right: 1px #ff7474 solid;
    height: 30px;
}

.buttonRigthZoom{
    width: 50px;
    border-bottom-right-radius: 20px;
    border-top-right-radius: 20px;
    border-left: 1px #ff7474 solid;
    height: 30px;
}