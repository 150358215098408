.containerNav {
    top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    z-index: 2;
    backdrop-filter: blur(20px);
    /* transform: translateY(0);
    transition: transform 0.3s; */
}

.divNav {
    box-sizing: border-box;
    width: 135px;
    /* padding-top: 20px; */
    /* -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px); */
    bottom: 0;
    height: 100%;
    flex-grow: 1;
}

/* .containerNav.hidden {
    transform: translateY(-100%);
} */

/* .navStripes {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 10px;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    cursor: pointer;
}

.navToggle {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 10px;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    cursor: pointer;
} */

.NavLinkContainer {
    display: flex;
    /* justify-content: center; */
    align-items: center;
    width: 100%;
    height: 90%;
    flex-direction: column;
    overflow-y: scroll;
    padding-top: 10px;
}

.additional-buttons {
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 160px;
    top: 150px;
    left: 95px;
    opacity: 0;
    transform: scale(0);
    transition: opacity 1.0s, transform 1.0s;
}

.additional-buttons.show {
    opacity: 1;
    transform: scale(1);
}

.additional-buttons2 {
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 160px;
    top: 250px;
    left: 95px;
    opacity: 0;
    transform: scale(0);
    transition: opacity 1.0s, transform 1.0s;
}

.additional-buttons2.show2 {
    opacity: 1;
    transform: scale(1);
}

.additional-buttons3 {
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 160px;
    top: 320px;
    left: 95px;
    opacity: 0;
    transform: scale(0);
    transition: opacity 1.0s, transform 1.0s;
}

.additional-buttons3.show3 {
    opacity: 1;
    transform: scale(1);
}

.optionsBottonNav.active {
    height: 20px;
    width: 20px;
}

.orientationBotton {
    margin-top: 5px;
    display: flex;
    flex-direction: row;
    background: linear-gradient(to top, rgba(82, 81, 81, 0.691) 70%, rgba(158, 46, 46, 0.126));
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
    box-shadow: 0 0 10px 0 #00000068 inset, 0 0 10px 4px #00000068;
    border-radius: 20px;
    height: 50px;
    transition: opacity 0.5s, transform 0.5s;
    z-index: 2;
}

.orientationBotton:hover {
    transform: translateY(-4px);
}

nav a {
    background: transparent;
    padding: 0.9rem;
    border-radius: 50%;
    display: flex;
    font-size: 1.1rem;
    transition: 0.5s;
    text-decoration: none;
    transition: 1000ms;
    height: 20px;
    width: 20px;

}

nav a:hover {
    backdrop-filter: blur(20px);
    height: 20px;
    width: 20px;
}

.activeNav.active {
    backdrop-filter: blur(20px);
    height: 20px;
    width: 20px;
}

/*Palomita*/
nav a .tooltipProduct {
    font-size: larger;
    visibility: hidden;
    width: 150px;
    background-color: rgba(0, 0, 0, 0.378);
    backdrop-filter: blur(20px);
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    bottom: 5px;
    margin-left: 50px;
}


nav a:hover .tooltipProduct {
    visibility: visible;
}

nav a .tooltipProductMenos {
    font-size: larger;
    visibility: hidden;
    width: 120px;
    background-color: rgba(0, 0, 0, 0.378);
    backdrop-filter: blur(20px);
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    left: 100%;
}

nav a .tooltipProductMenos::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 100%;
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent rgba(0, 0, 0, 0.378) transparent transparent;
    backdrop-filter: blur(20px);
    height: 20px;
    width: 20px;
}

nav a:hover .tooltipProductMenos {
    visibility: visible;
}

.textNavLink {
    font-size: smaller;
    color: white;
}

.textNavLinkUser {
    font-size: smaller;
    color: white;
}

.textNavLinkPlus {
    font-size: smaller;
    color: white;
}

.textNavLinkRest {
    font-size: smaller;
    color: white;
}

.textDivAdd {
    width: 0;
    z-index: 2;
    position: fixed;
    transform: translateX(-50%);
    right: 140px;
    border-radius: 3rem;
    display: flex;
    flex-direction: column;
}

.textNavLinkAdd {
    font-size: smaller;
    color: white;
}

.loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(255, 255, 255);
    /* Fondo difuminado con un 0% de opacidad */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    /* Asegura que el contenedor esté en la parte superior */
}

.loading-content {
    text-align: center;
}

.spin-icon {
    animation: spin 1s infinite linear;
    font-size: 3rem;
}

.menuButton2 {
    z-index: 3;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    height: 50px;
    font-size: 24px;
    padding-bottom: 10px;
    padding-top: 10px;
    box-sizing: border-box;
    width: 135px;
    /* -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px); */

}

/* .menuButton.hidden {
    display: none;
  } */

.faBarsIcon {
    height: 40px;
    width: 40px;
    display: none;
}

.HideComponent {
    display: none;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

@media screen and (max-width: 800px) {
    .divNav {
        display: block;
        width: 100px;
    }

    .containerNav{
        align-items: flex-start;
    }

    .menuButton2 {
        position: relative;
        left: 0px;
        width: 100px;
    }

    .faBarsIcon {
        display: block;
    }

    .NavLinkContainer{
        width: 100px;
        height: 80%;
    }
}

@media screen and (min-width: 801px) {
    .divNav {
        display: block !important;
        width: 100px;
    }

    .containerNav{
        align-items: flex-start;
        backdrop-filter: blur(20px)!important;
    }
    

    .menuButton2 {
        position: relative !important;
        left: 0px !important;
        width: 100px;
        padding-top: 0px;
        padding-bottom: 0px;
    }

    .faBarsIcon {
        display: none !important;
    }
}


@media screen and (max-width: 570px) {
    .tooltipProductMenos {
        display: none;
    }
}