.registerContainer{
    width: 100%;
    display: flex;
    justify-content: center;
    height: calc(100vh - 110px);
}

.drawerFormRegister{
    /* margin-bottom: -102px; */
    /* background-color: rgba(0, 0, 0, 0.3); */
    display: flex;
    flex-direction: column;
    /* text-align: center; */
    color: white;
    /* align-items: center; */
    /* justify-content: center; */
    backdrop-filter: blur(4px);
    width: 340px;
    border-radius: 10px;
    box-shadow: 15px 10px 10px black;
    padding-top: 40px;
    padding-bottom: 40px;
    margin-bottom: 10px;
    margin-top: 10px;
    overflow: auto;
}

.inputUsersContainer {
    color: black;
    position: relative;
    margin-bottom: 40px;
}

#registerPassword{
    left: 220px;
    bottom: 7px;
}

.inputLoginContainer {
    color: black;
    position: relative;
    margin-bottom: 20px;
}

.inputPasswordContainer {
    color: black;
    position: relative;
    margin-bottom: 20px;
}

.inputPasswordLoginContainer {
    color: black;
    position: relative;
    margin-bottom: 20px;
}
  
.inputUsersContainer .inputUsers {
    margin-top: 10px;
    border: none;
    border-bottom: 1px solid #ccc;
    color: white;
    height: 38px;
    width: 240px;
    /* padding: 10px; */
    padding-left: 10px;
    padding-right: 10px;
    /* padding-bottom: 20px; */
    font-size: 16px;
    transition: border-bottom-color 0.3s ease;
    background-color: transparent; /* Agregamos esta línea */
}

.inputLoginContainer .inputLogin {
    margin-top: 10px;
    border: none;
    border-bottom: 1px solid #ccc;
    color: white;
    height: 38px;
    width: 180px;
    padding: 10px;
    font-size: 16px;
    transition: border-bottom-color 0.3s ease;
    background-color: transparent; /* Agregamos esta línea */
}
 
.inputPasswordContainer .inputPasswordText {
    margin-top: 10px;
    border: none;
    border-bottom: 1px solid #ccc;
    color: white;
    height: 38px;
    width: 240px;
    padding: 10px;
    font-size: 16px;
    transition: border-bottom-color 0.3s ease;
    background-color: transparent; /* Agregamos esta línea */
}

.inputPasswordContainer .inputPasswordLoginText {
    margin-top: 10px;
    border: none;
    border-bottom: 1px solid #ccc;
    color: white;
    height: 38px;
    width: 240px;
    padding: 10px;
    font-size: 16px;
    transition: border-bottom-color 0.3s ease;
    background-color: transparent; /* Agregamos esta línea */
}

.inputUsersContainer .inputUsers:focus {
    outline: none;
    border-bottom-color: #007bff;
    background-color: #8bbcf025;
}

.inputLoginContainer .inputLogin:focus {
    outline: none;
    border-bottom-color: #007bff;
}

.inputPasswordContainer .inputPasswordText:focus {
    outline: none;
    border-bottom-color: #007bff;
}

.inputPasswordLoginContainer .inputPasswordLoginText:focus {
    outline: none;
    border-bottom-color: #007bff;
}
  
.inputUsersContainer label {
    position: absolute;
    bottom: 25px;
    left: 10px;
    font-size: 16px;
    color: #aaa;
    transition: all 0.3s ease;
    pointer-events: none;
}

.inputLoginContainer label {
    position: absolute;
    bottom: 25px;
    left: 10px;
    font-size: 16px;
    color: #aaa;
    transition: all 0.3s ease;
    pointer-events: none;
}
  
.inputPasswordContainer label {
    position: absolute;
    bottom: 25px;
    left: 10px;
    font-size: 16px;
    color: #aaa;
    transition: all 0.3s ease;
    pointer-events: none;
}

.inputPasswordContainer label {
    position: absolute;
    bottom: 25px;
    left: 10px;
    font-size: 16px;
    color: #aaa;
    transition: all 0.3s ease;
    pointer-events: none;
}
  
.inputUsersContainer .inputUsers:focus + label,
.inputUsersContainer .inputUsers:not(:placeholder-shown) + label {
    bottom: 40px;
    left: 5px;
    font-size: 12px;
    color: #007bff;

}

.inputLoginContainer .inputLogin:focus + label,
.inputLoginContainer .inputLogin:not(:placeholder-shown) + label {
    bottom: 50px;
    left: 5px;
    font-size: 12px;
    color: #007bff;
}

.inputPasswordContainer .inputPasswordText:focus + label,
.inputPasswordContainer .inputPasswordText:not(:placeholder-shown) + label {
    bottom: 50px;
    left: 5px;
    font-size: 12px;
    color: #007bff;
}

.inputPasswordContainer .inputPasswordLoginText:focus + label,
.inputPasswordContainer .inputPasswordLoginText:not(:placeholder-shown) + label {
    bottom: 50px;
    left: 5px;
    font-size: 12px;
    color: #007bff;
}

.inputUsersContainer .inputSelectNit {
    margin-top: 29px;
    border: none;
    border-bottom: 1px solid #ccc;
    height: 38px;
    width: 240px;
    padding: 10px;
    font-size: 16px;
    transition: border-bottom-color 0.3s ease;
    color: #888A8C;
    background-color: transparent;
}
  
.inputUsersContainer .inputSelectNit:focus {
    outline: none;
    border-bottom-color: #007bff;
}
  
.inputUsersContainer .inputSelectNit + label,
.inputUsersContainer .inputSelectNit:not(:placeholder-shown) + label {
    top: -20px;
    left: 5px;
    font-size: 12px;
    color: #007bff;
}

.password2{
    height: 30px;
    width: 30px;
    margin-left: -11px;
    margin-top: 3px;
    cursor: pointer;
}

.password2:hover{
    opacity: 0.7;
}

.buttonContainer{
    display: flex;
    flex-direction: row;
    margin-right: 50px;
    margin-left: 50px;
}

.visibleKey{
    display: flex;
    flex-direction: row;
}

.ContainerPassword1{
    margin-top: 15px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

.inputExteriorPasswordLeft1{
    background-color: white;
    height: 38px;
    width: 215px;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
}
  
.inputExteriorPasswordRight1{
    background-color: transparent;
    height: 38px;
    width: 40px;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
}

.password1{
    height: 30px;
    width: 30px;
    margin-left: -11px;
    margin-top: 3px;
    cursor: pointer;
}

.password1:hover{
    opacity: 0.7;
}

.inputPasswordRegister1{
    height: 38px;
    width: 240px;
    border-radius: 6px;
    padding-left: 15px;
    border: none;
    /* border: 2px solid #000000; */
    /* background-color: transparent; */
    color: rgb(0, 0, 0);
    background-repeat: no-repeat;
    background-size:10%;
    background-position: 210px;
    outline: none;
    transition: 0.2s;
  }
  
  /* .inputPasswordRegister1:hover{
      box-shadow: 0 0 10px 0 #db3434 inset, 0 0 10px 4px #db3434;
  } */

  @media screen and (max-width: 480px){
    .drawerFormRegister{
        width: 100%;
        margin-top: 0px;
    }
  }