
.containerLogoPdf {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 240px;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: 35px;
    box-sizing: border-box;
    justify-content: space-between;
}

.containerTableLogoPdf {
    display: flex;
    flex-direction: column;
    width: 57%;
    align-items: end;
}

.clientPdf {
    border: 1px solid #000000;
    /* height: 145px; */
    width: 40%;
    text-align: start;
    padding-left: 5px;
    padding-bottom: 10px;
    font-size: 20px;
    box-sizing: border-box;
}

.clientPdf10 {
    border: 1px solid #000000;
    /* height: 145px; */
    width: 60%;
    text-align: start;
    padding-left: 5px;
    padding-bottom: 10px;
    font-size: 20px;
    box-sizing: border-box;
}

.clientPdf2 {
    border: 1px solid #000000;
    height: 70px;
    width: 40%;
    text-align: start;
    padding-left: 5px;
    box-sizing: border-box;
    font-size: 18px;
}

.clientPdf3 {
    border: 1px solid #000000;
    height: 70px;
    width: 60%;
    text-align: start;
    padding-left: 5px;
    box-sizing: border-box;
    font-size: 18px;
}

.containerClientPdf {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 170px;
}

.containerClientPdf2 {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.containerGeneralPdf {
    width: 1400px;
    display: flex;
    flex-direction: column;
    background-color: white;
    color: black;
    transform-origin: center top;
}

.containerZoom{
    background-color: #e2e2e2 !important;
}

.divSeparation {
    /* width: 100%; */
    /* height: 10px; */
    /* padding-bottom: 3000px; */
    /* border-bottom: 2px solid rgba(0, 0, 0, 0.274); */
    /* border-color: black; */
    /* border-radius: 80%; */
    /* margin-top: 32px; */
    /* margin-bottom: 32px; */
}

.buttonContainerPdfTop{
    display: flex;
    width: 100px;
    flex-direction: row;
    justify-content: flex-start;
    height: 80px;
    position: fixed;
    margin-top: 10px;
    margin-left: 5px;
}

.buttonContainerPdf {
    display: flex;
    width: 100vw;
    flex-direction: row;
    justify-content: center;
    height: 80px;
    top: calc(100vh - 160px);
    /* padding-top: 20px; */
    /* padding-bottom: 20px; */
    /* min-width: 1100px; */
    position: fixed;
    /* bottom: 5px; */
    /* background-color: rgba(225, 225, 225, 0.371); */
}

.mainContainerPdf{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    
}

.containerScrollPdf{
    width: 100%;
    /* height: calc(100vh - 120px); */
    overflow: scroll;
}

.containerHeaderPdf {
    /* margin-top: 1848px !important; */
    height: 354px;
    box-sizing: border-box;
}

.canvasBlock{
    /* height: 1798px;
    box-sizing: border-box; */
}

.containerCanvasBlock{
    height: 1798px;
    box-sizing: border-box; 
}

.overallContainerPdf {
    display: flex;
    justify-content: center;
    align-items: start;
    flex-direction: column;
    width: 1400px;
    background-color: white;
    /* height: 1500px; */
    align-items: center;
    padding-left: 25px;
    padding-right: 25px;
}

.containerImageLogo {
    width: 40%;
    /* padding-right: 10%; */
    height: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
}

/* @supports(object-fit: cover) {
    .photo {
        object-fit: cover;
        object-position: center center;
    }
} */

.textDetail {
    text-align: center;
    font-size: x-large;
    color: #000000;
}

.itemNumberPdf {
    background: rgb(239, 239, 239);
    color: rgb(0, 0, 0);
    border-left: 1px solid #000000 !important;
    border-bottom: 1px solid #000000 !important;
    border-right: 1px solid #000000 !important;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    padding-left: 20px;
    padding-right: 20px;    /* Elimina la altura fija, lo que permite que el contenedor crezca según el contenido */
    height: 95%;
    flex-grow: 1;
}

.itemNumberPdfDescription {
    background: rgb(239, 239, 239);
    color: rgb(0, 0, 0);
    border-left: 1px solid #000000 !important;
    border-bottom: 1px solid #000000 !important;
    border-right: 1px solid #000000 !important;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1em;
    padding-left: 20px;
    padding-right: 20px;
    /* Elimina la altura fija, lo que permite que el contenedor crezca según el contenido */
    height: 95%;
    flex-grow: 1;
}

.itemNumberPdfHeard {
    background: rgb(105, 105, 106);
    color: rgb(255, 255, 255);
    border-left: 1px solid #000000 !important;
    border-top: 1px solid #000000 !important;
    border-right: 1px solid #000000 !important;
    border-bottom: 1px solid #000000 !important;
    height: 28px;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.itemNumberPdfHeardDescription {
    background: rgb(105, 105, 106);
    color: rgb(255, 255, 255);
    border-left: 1px solid #000000 !important;
    border-top: 1px solid #000000 !important;
    border-right: 1px solid #000000 !important;
    border-bottom: 1px solid #000000 !important;
    height: 28px;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.tableItemPdf3 {
    width: 7%;
}

.tableItemPdfDescription {
    width: 44%;
}

.tableItemPdfDPrice{
    width: 10%;
}
.tableItemPdfDPriceUnit{
    width: 14%;
}

.tableItemPdfIva{
    width: 10%;
}

.tableItemPdfDescriptionTotal{
    width: 15%;
}

.paragraphInfoClient {
    margin: 7px;
}

.formSpecificationsPdf {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.tableItemPdf4 {
    width: 100%;
}

.containerItemValue {
    width: 90%;
    margin-bottom: 10px;

}

.containerItemGeneric {
    box-sizing: border-box;
    display: flex;
    width: 100%;
    margin-bottom: 20px;
}

.containerFoooter{
    box-sizing: border-box;
    height: 515px;
}

.withoutMargin{
    margin-top: 0px;
}

.sizeLetter{
    font-size: 15px;
}

.observatioSpecifications {
    width: 50%;
    font-size: 20px;
    height: 32.4px;
    background: rgb(239, 239, 239);
    color: rgb(0, 0, 0);
    border-right: 1px solid #000;
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;
    border-left: 1px solid #000;
    display: flex;
    align-items: center;
    padding-left: 10px;
}

.ContainerTablePdfObservation {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    height: auto;
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 50px;
    margin-top: 5px;
    box-sizing: border-box;
}

.observation {
    font-size: x-large;
    padding-left: 5px;
    width: 55%;
    min-height: 168px;
    height: 100%;
    border: 1px solid #000000;
    font-size: 20px;
    height: 350px;
    word-wrap: break-word;
}

.containerFirma {
    width: 100%;
    padding-left: 30px;
    padding-right: 30px;
    box-sizing: border-box;
}

.firmaGeneric {
    display: flex;
    flex-direction: center;
    width: 100%;
    font-size: 20px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 100px;
    margin-bottom: 100px;
}

.textFirma {
    width: 50%;
    color: #000;
    display: flex;
    justify-content: center;
}

.textFirmaLineGeneric {
    width: 200px;
    color: #000000;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid black;
}

.textDescription {
    text-align: center;
    width: 100%;
    font-size: 17px;
    margin: 3px;
}

@media screen and (max-width: 1164px) {




}


.tablePdf7 {
    width: 45%;
}

.containerFirmaGeneric {
    width: 100%;
}

.firma {
    display: flex;
    flex-direction: row;
    width: 100%;
    font-size: 20px;
}

/* .firmaText{
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-around;
    align-items: center;
    margin-top: 150px;
    margin-bottom: 100px;
} */


.containerTextDescription {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
